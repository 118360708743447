export const shortenString = (string, length) => {
  if (string.length <= length) {
    return string;
  } else {
    return (
      <>
        {string.substr(0, length - 3)}
        <span style={{ fontWeight: 900 }}>...</span>
      </>
    );
  }
};
