export function formattedDate() {
  const currentDate = new Date();
  const dd = currentDate.getDate();
  const mm = currentDate.getMonth() + 1; // getMonth() is zero-based
  const yyyy = currentDate.getFullYear();

  const date = new Date(yyyy, mm - 1, dd); // Note: Months are zero-based in JavaScript Dates

  return date;
}
