import React from "react";
import { API_URL } from "../config";

function MediumReklama({ ads, animate }) {
  let mediumAd = null;
  ads.forEach((ad) => {
    if (ad.size === "large") {
      mediumAd = ad.image;
    }
  });

  return (
    <div
      data-aos={animate === false ? "none" : "fade-up"}
      className="d-flex align-items-center justify-content-center"
      style={{ marginTop: "20px" }}
    >
      {mediumAd ? (
        <img
          alt="medium ad"
          style={{
            maxWidth: "100%",
            maxHeight: "650px",
          }}
          src={`${API_URL}/` + mediumAd}
        />
      ) : (
        "Vaša reklama na: 067 XXX XXX"
      )}
    </div>
  );
}

export default MediumReklama;
