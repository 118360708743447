import React, { useState, useEffect } from "react";
import { Container, Button, Modal, Row, Col } from "react-bootstrap";
import User from "./User";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Users from "./Users";
import Admins from "./Admins";
import CreateAdmin from "./CreateAdmin";
import CreateUser from "./CreateUser";
import PoslednjiPozdrav from "./PoslednjiPozdrav";
import MediumPPozdrav from "./MediumPPozdrav";
import SmallPoslednjiPozdrav from "./SmallPoslednjiPozdrav";
import SmallUmrlica from "./SmallUmrlica";
import Umrlica from "./Umrlica";
import BigUmrlica from "./BigUmrlica";
import Pomen from "./Pomen";
import MediumPomen from "./MediumPomen";
import BigPomen from "./BigPomen";
import QuarterUmrlica from "./QuarterUmrlica";
import QuarterPomen from "./QuarterPomen";
import QuarterPP from "./QuarterPP";
import ChangeUserPassword from "./ChangeUserPassword";
import EditPrices from "./EditPrices";
import MyPosts from "./MyPosts";
import AdsTab from "./AdsTab";
import CurrentAds from "./CurrentAds";
import { API_URL, UMRLICE_LARAVEL_API_TOKEN } from "../config";
import axios from "axios";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

const UserProfile = ({ setIsLoggedIn, isLoggedIn, ads }) => {
  const [pendingPosts, setPendingPosts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [regularUsers, setRegularUsers] = useState([]);
  const [adminUsers, setAdminUsers] = useState([]);
  const userRole = localStorage.getItem("userRole");
  const [key, setKey] = useState("profil");

  const handleCloseModal = () => {
    setSelectedPost(null);
    setShowModal(false);
  };

  const handleShowModal = (post) => {
    setSelectedPost(post);
    setShowModal(true);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  useEffect(() => {
    fetchUsers();
  }, []);
  const fetchUsers = () => {
    const accessToken = localStorage.getItem("accessToken");
    if (userRole === "admin" || userRole === "superadmin") {
      fetch(`${API_URL}/api/accounts/all`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          const regular = data.filter((user) => user.type === "regular");
          setRegularUsers(regular);
          const admin = data.filter((user) => user.type === "admin");
          setAdminUsers(admin);
        })
        .catch((error) => {
          console.error("Error fetching users:", error);
        });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const accessToken = localStorage.getItem("accessToken");
    if (selectedFile) {
      const formData = new FormData();
      formData.append("video", selectedFile);

      try {
        const response = await fetch(`${API_URL}/api/video_upload/`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: formData,
        });

        if (response.ok) {
          // Handle success
          console.log("Video uploaded successfully");
        } else {
          // Handle error
          console.error("Video upload failed");
        }
      } catch (error) {
        console.error("Error uploading video:", error);
      }
    }
  };

  useEffect(() => {
    fetchPendingPosts();
  }, []);
  const fetchPendingPosts = async () => {
    try {
      const response = await fetch(`${API_URL}/api/posts/administration/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

      if (response.ok) {
        const data = await response.json();

        setPendingPosts(data);
      } else {
        // Handle unauthorized or error response
        console.log("Error fetching pending posts:", response.status);
      }
    } catch (error) {
      console.log("Error fetching pending posts:", error);
    }
  };
  const handleAcceptPost = async (post) => {
    const findUser = regularUsers.find((e) => e.email == post.email);

    if (findUser) {
      axios
        .get(
          `https://www.umrlice-api.com/api/${UMRLICE_LARAVEL_API_TOKEN}/payment/capture?user_id=${findUser.id}&post_id=${post.id}`
        )
        .then(async (res) => {
          if (res.data.success == true) {
            try {
              const response = await fetch(
                `${API_URL}/api/posts/${post.id}/approve/`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem(
                      "accessToken"
                    )}`,
                  },
                }
              );

              if (response.ok) {
                console.log("Post approved successfully");
                // Remove the accepted post from the pending posts
                const updatedPosts = pendingPosts.filter(
                  (p) => p.id !== post.id
                );
                setPendingPosts(updatedPosts);
                // Update the pending posts on the server
                await updatePendingPostsOnServer(updatedPosts);
              } else {
                // Handle unauthorized or error response
                console.log("Error approving post:", response.status);
              }
            } catch (error) {
              console.log("Error approving post:", error);
            }
          }
          if (
            res.data?.message &&
            (res.data.message == "failed" || res.data.message == "yet")
          ) {
            NotificationManager.error("Korisnik još nije izvršio uplatu");
          }
        })
        .catch((err) => {
          console.log({ capturePaymentError: err });
        });
    } else {
      if (
        localStorage.getItem("userRole") != null &&
        localStorage.getItem("userRole") == "superadmin"
      ) {
        let ownerPosts = [];

        const headers = {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        };

        axios
          .get(`${API_URL}/api/posts/owners_posts/`, {
            headers,
          })
          .then(async (res) => {
            ownerPosts = res.data;

            let isCreatedByAdmin = ownerPosts.findIndex(
              (e) => e.id === post.id
            );

            if (isCreatedByAdmin !== -1) {
              try {
                const response = await fetch(
                  `${API_URL}/api/posts/${post.id}/approve/`,
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                      )}`,
                    },
                  }
                );

                if (response.ok) {
                  console.log("Post approved successfully");
                  // Remove the accepted post from the pending posts
                  const updatedPosts = pendingPosts.filter(
                    (p) => p.id !== post.id
                  );
                  setPendingPosts(updatedPosts);
                  // Update the pending posts on the server
                  await updatePendingPostsOnServer(updatedPosts);
                } else {
                  // Handle unauthorized or error response
                  console.log("Error approving post:", response.status);
                }
              } catch (error) {
                console.log("Error approving post:", error);
              }
            }
          })
          .catch((err) => {
            console.log({ capturePaymentError: err });
          });
      }
    }
  };
  const handleUpdateButton = (id) => {
    window.location.href = `/posts/update/${id}`;
  };
  const handleDeclinePost = async (post) => {
    const findUser = regularUsers.find((e) => e.email == post.email);
    if (findUser) {
      axios
        .get(
          `https://www.umrlice-api.com/api/${UMRLICE_LARAVEL_API_TOKEN}/payment/void?user_id=${findUser.id}&post_id=${post.id}`
        )
        .then(async (res) => {
          if (res.data.success == true) {
            try {
              const response = await fetch(
                `${API_URL}/api/posts/${post.id}/` ||
                  `${API_URL}/api/posts/${selectedPost.id}/`,
                {
                  method: "DELETE",
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                      "accessToken"
                    )}`,
                  },
                }
              );

              if (response.ok) {
                console.log("Post declined successfully");
                // Remove the declined post from the pending posts
                const updatedPosts = pendingPosts.filter(
                  (p) => p.id !== post.id
                );
                setPendingPosts(updatedPosts);
                // Update the pending posts on the server
                await updatePendingPostsOnServer(updatedPosts);
              } else {
                // Handle unauthorized or error response
                console.log("Error declining post:", response.status);
              }
            } catch (error) {
              console.log("Error declining post:", error);
            }
          }
        })
        .catch((err) => {
          console.log({ voidPaymentError: err });
        });
    } else {
        if (localStorage.getItem('userRole') != null && localStorage.getItem('userRole') == 'superadmin') {
            let ownerPosts = [];

            const headers = {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            };

            axios.get(`${API_URL}/api/posts/owners_posts/`, {
              headers
            })
                .then(async res => {
                  ownerPosts = res.data;

                  let isCreatedByAdmin = ownerPosts.findIndex(e => e.id === post.id);

                  if (isCreatedByAdmin !== -1) {
                    try {
                      const response = await fetch(
                          `${API_URL}/api/posts/${post.id}/` ||
                          `${API_URL}/api/posts/${selectedPost.id}/`,
                          {
                            method: "DELETE",
                            headers: {
                              Authorization: `Bearer ${localStorage.getItem(
                                  "accessToken"
                              )}`,
                            },
                          }
                      );

                      if (response.ok) {
                        console.log("Post declined successfully");
                        // Remove the declined post from the pending posts
                        const updatedPosts = pendingPosts.filter(
                            (p) => p.id !== post.id
                        );
                        setPendingPosts(updatedPosts);
                        // Update the pending posts on the server
                        await updatePendingPostsOnServer(updatedPosts);
                      } else {
                        // Handle unauthorized or error response
                        console.log("Error declining post:", response.status);
                      }
                    } catch (error) {
                      console.log("Error declining post:", error);
                    }
                  }
                })
                .catch((err) => {
                  console.log({ capturePaymentError: err });
                });
        }
    }
  };

  const updatePendingPostsOnServer = async (posts) => {
    try {
      const response = await fetch(`${API_URL}/api/pending-posts`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify(posts),
      });

      if (response.ok) {
        console.log("Pending posts updated on the server");
      } else {
        // Handle unauthorized or error response
        console.log("Error updating pending posts:", response.status);
      }
    } catch (error) {
      console.log("Error updating pending posts:", error);
    }
  };
  return (
    <Container style={{ marginTop: "5%", paddingTop: "100px" }}>
      <h2>Tvoj profil</h2>
      <Tabs
        style={{ paddingBottom: "10px", paddingTop: "20px" }}
        defaultActiveKey="profil"
        id="uncontrolled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
      >
        <Tab id="tab" eventKey="profil" title="Profil">
          <User isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
        </Tab>
        <Tab id="tab" eventKey="moje-objave" title="Moje objave">
          <MyPosts />
        </Tab>
        {userRole !== "regular" && (
          <Tab id="tab" eventKey="korisnici" title="Korisnici">
            <Users fetchUsers={fetchUsers} regularUsers={regularUsers} />
          </Tab>
        )}

        {userRole !== "regular" && userRole !== "admin" && (
          <Tab id="tab" eventKey="admini" title="Admini">
            <Admins fetchUsers={fetchUsers} adminUsers={adminUsers} />
          </Tab>
        )}

        <Tab id="tab" eventKey="Izmijeni-lozinku" title="Promijeni lozinku">
          <ChangeUserPassword />
        </Tab>

        {userRole !== "regular" && (
          <Tab id="tab" eventKey="dodaj-korisnika" title="Kreiraj korisnika">
            <CreateUser />
          </Tab>
        )}

        {userRole !== "regular" && userRole !== "admin" && (
          <Tab id="tab" eventKey="kreiraj-admina" title="Kreiraj admina">
            <CreateAdmin />
          </Tab>
        )}

        {userRole !== "regular" && (
          <Tab id="tab" eventKey="reklame" title="Upravljaj reklamama">
            <AdsTab />
          </Tab>
        )}
        {userRole !== "regular" && (
          <Tab id="tab" eventKey="aktivne-reklame" title="Aktivne reklame">
            <CurrentAds ads={ads} />
          </Tab>
        )}

        {userRole !== "regular" && (
          <Tab
            id="tab"
            eventKey="objave-na-cekanju"
            title="Objave na čekanju  "
          >
            {userRole === "admin" || userRole === "superadmin" ? (
              <div>
                <h2 style={{ marginTop: "20px", textAlign: "center" }}>
                  Objave na čekanju
                </h2>
                <Container>
                  <Row>
                    {pendingPosts.length > 0 ? (
                      pendingPosts
                        .filter((post) => post.status !== "approved")
                        .map((post) => (
                          <Col key={[post.id]} lg={4}>
                            <div className="pending-card" key={post.id}>
                              <h6>Email: {post.email}</h6>
                              <h6>
                                Broj telefona kreatora:{post.phone_number}
                              </h6>
                              <h6>Napomena:{post.note}</h6>
                              <h6>
                                Datum objave:{" "}
                                {new Date(post.date_created).toLocaleDateString(
                                  "sr-RS"
                                )}
                              </h6>
                              <Button
                                style={{
                                  backgroundColor: "lightblue",
                                  border: "none",
                                }}
                                onClick={() => handleShowModal(post)}
                              >
                                Pogledaj
                              </Button>{" "}
                              <Button
                                variant="success"
                                onClick={() => handleAcceptPost(post)}
                              >
                                Prihvati
                              </Button>{" "}
                              <Button
                                onClick={() => handleUpdateButton(post.id)}
                                style={{
                                  backgroundColor: "#c9b904",
                                  border: "none",
                                  color: "white",
                                }}
                              >
                                Uredi
                              </Button>{" "}
                              <Button
                                variant="danger"
                                onClick={() => handleDeclinePost(post)}
                              >
                                Odbij
                              </Button>
                            </div>
                          </Col>
                        ))
                    ) : (
                      <p>Objave se očitavaju ili ne postoje...</p>
                    )}
                  </Row>
                </Container>
              </div>
            ) : null}
            <Modal
              contentlabel="Post Modal"
              size={
                selectedPost &&
                (selectedPost.size === 1 || selectedPost.size === 2)
                  ? "md"
                  : "lg"
              }
              style={{ padding: "0px" }}
              show={showModal}
              onHide={handleCloseModal}
            >
              <Container
                style={{
                  paddingLeft: "8%",
                  paddingRight: "8%",
                  paddingTop: "20px",
                }}
              >
                <Row>
                  {selectedPost &&
                    selectedPost.type === "poslednji_pozdrav" &&
                    selectedPost.size === 3 && (
                      <PoslednjiPozdrav post={selectedPost} />
                    )}
                  {selectedPost &&
                    selectedPost.type === "poslednji_pozdrav" &&
                    selectedPost.size === 2 && (
                      <MediumPPozdrav post={selectedPost} />
                    )}
                  {selectedPost &&
                    selectedPost.type === "poslednji_pozdrav" &&
                    selectedPost.size === 1 && (
                      <SmallPoslednjiPozdrav post={selectedPost} />
                    )}
                  {selectedPost &&
                    selectedPost.type === "umrlica" &&
                    selectedPost.size === 1 && (
                      <SmallUmrlica post={selectedPost} />
                    )}
                  {selectedPost &&
                    selectedPost.type === "umrlica" &&
                    selectedPost.size === 2 && <Umrlica post={selectedPost} />}
                  {selectedPost &&
                    selectedPost.type === "umrlica" &&
                    selectedPost.size === 3 && (
                      <BigUmrlica post={selectedPost} />
                    )}
                  {selectedPost &&
                    selectedPost.type === "pomen" &&
                    selectedPost.size === 1 && <Pomen post={selectedPost} />}
                  {selectedPost &&
                    selectedPost.type === "pomen" &&
                    selectedPost.size === 2 && (
                      <MediumPomen post={selectedPost} />
                    )}
                  {selectedPost &&
                    selectedPost.type === "pomen" &&
                    selectedPost.size === 3 && <BigPomen post={selectedPost} />}
                  {selectedPost &&
                    selectedPost.type === "umrlica" &&
                    selectedPost.size === 4 && (
                      <QuarterUmrlica post={selectedPost} />
                    )}
                  {selectedPost &&
                    selectedPost.type === "pomen" &&
                    selectedPost.size === 4 && (
                      <QuarterPomen post={selectedPost} />
                    )}
                  {selectedPost &&
                    selectedPost.type === "poslednji_pozdrav" &&
                    selectedPost.size === 4 && (
                      <QuarterPP post={selectedPost} />
                    )}
                </Row>
                <button
                  style={{
                    float: "right",
                    borderRadius: "4px",
                    marginTop: "2%",
                    padding: "5px",
                    backgroundColor: "gray",
                    color: "white",
                  }}
                  onClick={handleCloseModal}
                >
                  Zatvori
                </button>
              </Container>
            </Modal>
            {!pendingPosts && (
              <h4 style={{ textAlign: "center", marginTop: "5%" }}>
                Objave na čekanju se očitavaju...
              </h4>
            )}
          </Tab>
        )}
        {userRole !== "regular" && (
          <Tab
            id="tab"
            eventKey="dodaj-video-citulju"
            title="Kreiraj video čitulju"
          >
            <form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: "20px",
                padding: "20px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                backgroundColor: "#f5f5f5",
              }}
            >
              <label
                htmlFor="videoUpload"
                style={{ fontWeight: "bold", marginBottom: "10px" }}
              >
                Upload Video
              </label>
              <input
                type="file"
                id="videoUpload"
                accept="video/*"
                onChange={handleFileChange}
                style={{ marginBottom: "10px" }}
              />
              <button
                type="submit"
                style={{
                  backgroundColor: "#007bff",
                  color: "#fff",
                  padding: "10px 20px",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  transition: "background-color 0.3s ease",
                }}
              >
                Submit
              </button>
            </form>
          </Tab>
        )}

        {userRole === "superadmin" && (
          <Tab id="tab" eventKey="cijene" title="Uredi cijene">
            <EditPrices />
          </Tab>
        )}
      </Tabs>
      <NotificationContainer animation={false} />
    </Container>
  );
};

export default UserProfile;
