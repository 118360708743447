import React from "react";
import { Formik, Field, Form } from "formik";
import { Container, Col, Row, Button } from "react-bootstrap";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { API_URL } from "../config";

const ForgotPasswordPage = () => {
  const handleSubmit = (values) => {
    // Extract the email from the form values
    const { username } = values;

    // Create the payload to send to the API
    const payload = {
      email: username,
    };

    // Send a POST request to the API
    fetch(`${API_URL}/api/reset_password/send_mail/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response from the API if needed
        setTimeout(() => {
          window.location.href = "/forgot-password-success";
        }, 2000);
        NotificationManager.success(
          `Poslali smo vam link za promjenu Vaše lozinke na mail.`
        );
      })
      .catch((error) => {
        console.error("Error sending email to the API:", error);
        // Handle the error, show a message to the user, etc.

        NotificationManager.error(
          `Greška. Provjerite da li nalog postoji i pokušajte ponovo.`
        );
      });
  };

  return (
    <Container style={{ paddingTop: "120px" }}>
      <Formik
        initialValues={{
          username: "",
          password: "",
        }}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <div style={{ maxWidth: "500px", margin: "0 auto" }}>
            <h1 style={{ textAlign: "center" }}>Zaboravljena lozinka</h1>
            <p className="mb-3 mt-3 text-center" style={{ fontSize: "15px" }}>
              Unesite email adresu sa kojom ste registrovani na našu aplikaciju
              kako bismo Vam poslali link za resetovanje lozinke
            </p>
            <Form
              onKeyDown={(e) => {
                if (e.key == "Enter") {
                  handleSubmit(values);
                }
              }}
            >
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <Field
                type="text"
                name="username"
                id="email"
                className="form-control"
                placeholder="Unesite email..."
              />
              <Row>
                <Col lg={8}>
                  <Button
                    style={{
                      color: "white",
                      backgroundColor: "black",
                      border: "none",
                      marginTop: "10px",
                    }}
                    className="register-btn"
                    type="submit"
                    variant="primary"
                  >
                    Potvrdi
                  </Button>
                </Col>

                <Col style={{ padding: "15px" }} lg={4}>
                  <a
                    style={{
                      textDecoration: "underline",
                      color: "black",
                      fontSize: "15px",
                    }}
                    href="/login"
                  >
                    Nazad na login
                  </a>
                </Col>
              </Row>
            </Form>
          </div>
        )}
      </Formik>
      <NotificationContainer />
    </Container>
  );
};

export default ForgotPasswordPage;
