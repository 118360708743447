import React from "react";

const FunrealServices = () => {
  return (
    <div className="container" style={{ paddingTop: "140px" }}>
      <h2 className="text-center">Pogrebne usluge</h2>
      <p className="mt-3" style={{ fontStyle: "italic" }}>
        ADRESAR GROBLJA:
      </p>

      <h3 class="mt-3">Podgorica</h3>
      <p class="mt-3">
        <strong>Pogrebne usluge d.o.o.</strong>
        <br />
        <i>Adresa:</i> 8. Mart bb
        <br />
        <i>Telefon:</i> +382 20 634 022; +382 67 045 751
        <br />
        <i>Faks:</i> +382 20 634 202
        <br />
        <i>E-mail:</i> pogrebneusluge@t-com.me
        <br />
        <i>Web site:</i>{" "}
        <a href="http://www.pogrebneuslugepg.me">www.pogrebneuslugepg.me</a>
        <br />
        <i>Mjesne zajednice:</i>{" "}
        <a href="http://www.podgorica.me/mjesne-zajednice">
          www.podgorica.me/mjesne-zajednice
        </a>
      </p>

      <p class="mt-3">
        <strong>Vododjelnica Lazović</strong>
        <br />
        <i>Adresa:</i> 8. Mart bb
        <br />
        <i>Telefon:</i> +382 067 283 991
      </p>
      <h3 class="mt-3">Danilovgrad</h3>
      <p class="mt-3">
        <strong>Javno komunalno zanatsko preduzeće</strong>
        <br />
        <i>Adresa:</i> Ul. Jevta Caja Šćepanovića bb
        <br />
        <i>Telefon:</i> +382 20 811 550; +382 20 812 720; +382 68 008 118
      </p>

      <h3 class="mt-3">Nikšić</h3>
      <p class="mt-3">
        <strong>Gradsko pogrebno</strong>
        <br />
        <i>Telefon:</i> +382 40 213 494; +382 67 205 848
        <br />
        Mail: jkpogrebnonk@t-com.me ; komunalnonk@t-com.me
        <br />
        Web adresa:{" "}
        <a href="http://www.pogrebnoniksic.me/">www.pogrebnoniksic.me</a>
        <br />
        Mjesne zajednice:{" "}
        <a href="http://niksic.me/o-niksicu/mjesne-zajednice/">
          niksic.me/o-niksicu/mjesne-zajednice/
        </a>
      </p>

      <p class="mt-3">
        <strong>Pogrebne usluge „Narcis“</strong>
        <br />
        <i>Adresa:</i> Vuka Mićunovića bb, Nikšić
        <br />
        <i>Telefon:</i> +382 40 214 941, +382 69 503 144, +382 69 853 200
        <br />
        <i>E-mail:</i> p.u.narcis@t-com.me
      </p>

      <p class="mt-3">
        <strong>Pogrebne usluge „Lončar“</strong>
        <br />
        <i>Adresa:</i> Nikca od Rovina bb, Nikšić
        <br />
        <i>Telefon:</i> +382 69 540 368
        <br />
        <i>E-mail:</i> rloncar@t-com.me
      </p>
      <h3 class="mt-3">Bar</h3>
      <p class="mt-3">
        <strong>Doo Komunalne djelatnosti</strong>
        <br />
        <i>Adresa:</i> Gradska kapela Belveder, Stari Bar
        <br />
        <i>Telefon:</i> +382 30 342 266; +382 68 001 742; +382 68 108 172; +382
        68 393 867
      </p>

      <p class="mt-3">
        <strong>DKM-POGREBNO MALJEVIĆ</strong>
        <br />
        <i>Adresa:</i> Rista Lekića 12, Bar
        <br />
        <i>Telefon:</i> 067/064-458, 069/064-458, 068/064-458, 069/277-218
        <br />
        <i>E-mail:</i> dkm.maljevic@gmail.com
      </p>

      <h3 class="mt-3">Bijelo Polje</h3>
      <p class="mt-3">
        <strong>Pogrebno preduzeće Sotiroski</strong>
        <br />
        <i>Adresa:</i> III sandžačke brigade 63
        <br />
        <i>Telefon:</i> +382 50 431 050; +382 69 023 690; +382 69 600 063
        <br />
        <i>E-mail:</i> sotiroskibp@t-com.me
      </p>

      <p class="mt-3">
        <strong>
          Javno preduzeće za komunalno stambenu djelatnost “Lim” Bijelo Polje
        </strong>
        <br />
        <i>Adresa:</i> Ulica Živka Žižića br. 24, 84000 Bijelo Polje
        <br />
        <i>Telefon/fax:</i> +382 50 432 510
        <br />
        <i>E-mail:</i> jkplimbijelopolje@hotmail.com
        <br />
        <i>Mjesne zajednice:</i>{" "}
        <a href="http://www.bijelopolje.co.me/index.php/gradjanska-prava/mjesne-zajednice">
          www.bijelopolje.co.me/index.php/gradjanska-prava/mjesne-zajednice
        </a>
      </p>
      <h3 class="mt-3">Berane</h3>
      <p class="mt-3">
        <strong>Javno preduzeće Komunalno</strong>
        <br />
        <i>Adresa:</i> ul. Dragiše Radevića bb
        <br />
        <i>Telefon:</i> +382 51 233 338; +382 51 237 098
        <br />
        <i>Fax:</i> +382 51 237 099
        <br />
        <i>E-mail:</i> jpkomba@t-com.me
        <br />
        <i>Mjesne zajednice:</i>{" "}
        <a href="http://www.berane.me/index.php?IDSP=250&jezik=lat">
          www.berane.me/index.php?IDSP=250&jezik=lat
        </a>
      </p>

      <h3 class="mt-3">Budva</h3>
      <p class="mt-3">
        <strong>Javno preduzeće „Pogrebne usluge“</strong>
        <br />
        <i>Adresa:</i> Potkošljun bb, 85310
        <br />
        <i>Telefon:</i> +382 33 458 624; +382 30 458 625
        <br />
        <i>Fax:</i> +382 33 458 627
        <br />
        <i>E-mail:</i> pogrebneusluge.budva@t-com.me
        <br />
        Mjesne zajednice:{" "}
        <a href="http://new.budva.me/predsjednici-mjesnih-zajednica">
          new.budva.me/predsjednici-mjesnih-zajednica
        </a>
      </p>

      <h3 class="mt-3">Cetinje</h3>
      <p class="mt-3">
        <strong>Javno komunalno preduzeće</strong>
        <br />
        <i>Adresa:</i> Jaroslava Čermaka bb
        <br />
        <i>Telefon:</i> +382 41 238 225
        <br />
        <i>Fax:</i> +382 41 238 143
        <br />
        <i>E-mail:</i> jkp.cetinje@gmail.com
        <br />
        <i>Mjesne zajednice:</i>{" "}
        <a href="http://www.cetinje.me/index.php/me/gradska-uprava/mjesna-samouprava/mjesne-zajednice">
          www.cetinje.me/index.php/me/gradska-uprava/mjesna-samouprava/mjesne-zajednice
        </a>
      </p>

      <h3 class="mt-3">Herceg Novi</h3>
      <p class="mt-3">
        <strong>Javno „Komunalno-stambeno“ preduzeće</strong>
        <br />
        <i>Adresa:</i> Marka Vojnovića br. 1 85340 Herceg Novi
        <br />
        <i>Telefon:</i> +382 31 324 999
        <br />
        Faks: +382 31 324 551
        <br />
        <i>E-mail:</i> komunalnohn@t-com.me
        <br />
        Mjesne zajednice:{" "}
        <a href="http://www.hercegnovi.me/index.php?option=com_content&view=article&id=41">
          www.hercegnovi.me/index.php?option=com_content&view=article&id=41
        </a>
      </p>
      <h3 class="mt-3">Kolašin</h3>
      <p class="mt-3">
        <strong>Javno komunalno preduzece</strong>
        <br />
        <i>Adresa:</i> Mirka Vešovića bb, Kolašin
        <br />
        <i>Telefon:</i> +382 20 865 455
      </p>

      <h3 class="mt-3">Kotor</h3>
      <p class="mt-3">
        <strong>Javno komunalno preduzeće „Kotor“</strong>
        <br />
        <i>Adresa:</i> Škaljari bb, 85330 Kotor
        <br />
        <i>Telefon/Fax:</i> +382 32 325 677
        <br />
        <i>Website:</i> <a href="http://www.jkpkotor.com">www.jkpkotor.com</a>
        <br />
        <i>E-mail:</i> kapela@t-com.me
        <br />
        <i>Mjesne zajednice:</i>{" "}
        <a href="http://kotor.me/me/mjesne-zajednice">
          kotor.me/me/mjesne-zajednice
        </a>
      </p>

      <p class="mt-3">
        <strong>Pogrebne usluge „Poslednji ispraćaj“</strong>
        <br />
        <i>Adresa:</i> Poslovni centar Škaljari-Rakite, Kotor
        <br />
        <i>Telefon:</i> 069/645-022, 069/571-049, 069/233-391
        <br />
        <i>E-mail:</i> sandrajksmvc@yahoo.com
        <br />
        <i>Website:</i> <a href="http://www.pogrebno.me">www.pogrebno.me</a>
      </p>

      <h3 class="mt-3">Opština Mojkovac</h3>
      <p class="mt-3">
        <i>Telefon:</i> +382 50 470 272; +382 50 472 715
        <br />
        <i>E-mail:</i> opstinamojkovac@t-com.me
        <br />
        <i>Mjesne zajednice:</i>{" "}
        <a href="http://www.mojkovac.me/index.php?option=com_content&view=article&id=3&It">
          www.mojkovac.me/index.php?option=com_content&view=article&id=3&It
        </a>
      </p>

      <h3 class="mt-3">Plužine</h3>
      <p class="mt-3">
        Javno komunalno stambeno preduzeće
        <br />
        <i>Telefon:</i> +382 40 271 113
      </p>

      <h3 class="mt-3">Rožaje</h3>
      <p class="mt-3">
        <strong>Javno preduzeće “Komunalno”</strong>
        <br />
        <i>Adresa:</i> Jaha Kurtagica bb, 84 310 Rožaje
        <br />
        <i>Telefon:</i> +382 51 271 330; +382 68 838 500;
        <br />
        <i>E-mail:</i> jpk.r@live.com;
        <br />
        <i>Web site:</i>{" "}
        <a href="http://www.komunalnorozaje.me">www.komunalnorozaje.me</a>
        <br />
        <i>Mjesne zajednice:</i>{" "}
        <a href="http://www.rozaje.me/?page_id=1631">
          www.rozaje.me/?page_id=1631
        </a>
      </p>
      <h3 class="mt-3">Opština Šavnik</h3>
      <p class="mt-3">
        <i>Telefon:</i> +382 40 266 103; +382 40 266 108
        <br />
        Faks: +382 40 266 231
        <br />
        <i>E-mail:</i> sosavnik@t-com.me
        <br />
        Web <i>Adresa:</i> <a href="http://www.savnik.me">www.savnik.me</a>
      </p>

      <h3 class="mt-3">Tivat</h3>
      <p class="mt-3">
        <strong>DOO „Komunalno“</strong>
        <br />
        <i>Adresa:</i> Luke Tomanovića br. 7, Tivat.
        <br />
        <i>Telefon:</i> +382 32 671 527
        <br />
        <i>Fax:</i> +382 32 671 039
        <br />
        <i>E-mail:</i> kom-tivat@t-com.me
        <br />
        <i>Web site:</i>{" "}
        <a href="http://www.komunalnotivat.com/">www.komunalnotivat.com</a>
        <br />
        <i>Mjesne zajednice:</i>{" "}
        <a href="http://opstinativat.com/lokalna-samouprava/mjesne-zajednice.html">
          opstinativat.com/lokalna-samouprava/mjesne-zajednice.html
        </a>
      </p>

      <h3 class="mt-3">Ulcinj</h3>
      <p class="mt-3">
        <strong>Javno komunalno preduzeće</strong>
        <br />
        <i>Adresa:</i> Ul. Majke Tereze bb, Ulcinj
        <br />
        <i>Telefon:</i> +382 85 425 003; +382 85 425 004
        <br />
        <i>Fax:</i> +382 85 425 005
        <br />
        <i>Web site:</i> <a href="http://www.jpkd-ul.me">www.jpkd-ul.me</a>
      </p>

      <h3 class="mt-3">Opština Žabljak</h3>
      <p class="mt-3">
        <i>Adresa:</i> Trg Durmitorskih ratnika br. 1<br />
        <i>Telefon:</i> +382 52 361 419
        <br />
        <i>E-mail:</i> savazekovic@gmail.com
        <br />
        <i>Web site:</i> <a href="http://zabljak.me/">zabljak.me</a>
      </p>

      <h3 class="mt-3">Pljevlja</h3>
      <p class="mt-3">
        <strong>Javno preduzeće Komunalne usluge</strong>
        <br />
        <i>Adresa:</i> Mila Peruničića bb, 84210 Pljevlja
        <br />
        <i>Web site:</i> <a href="http://www.pljevlja.me/">www.pljevlja.me</a>
      </p>
    </div>
  );
};

export default FunrealServices;
