import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import { FiEye, FiEyeOff } from "react-icons/fi"; // Import eye icons
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import axios from "axios";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { API_URL, UMRLICE_LARAVEL_API_TOKEN } from "../config";

const apiUrl = `${API_URL}/api/login/`;

const Login = () => {
  const [showPassword, setShowPassword] = useState(false); // State for password visibility
  const [errorMessage, setErrorMessage] = useState("");

  const fetchLoggedInUserRole = async () => {
    try {
      const response = await fetch(`${API_URL}/api/accounts/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

      if (response.ok) {
        const data = await response.json();

        localStorage.setItem("userRole", data.type);
        localStorage.setItem("userId", data.id);
        localStorage.setItem("loggedInUserName", data.username);
      } else {
        // Handle unauthorized or error response
        console.log("Error fetching user data:", response.status);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    setErrorMessage("");
  }, []);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const response = await axios.post(apiUrl, values, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const { access } = response.data;

      // Check if tokens are received
      if (access) {
        // Store the tokens in local storage
        localStorage.setItem("accessToken", access);

        // You can store the refresh token in the same way if you have one
        localStorage.setItem("refreshToken", response.data.refresh);

        // Include the access token in subsequent requests
        axios.defaults.headers.common["Authorization"] = `Bearer ${access}`;
        // Redirect to the home page

        console.log("fetchLoggedInUserRole START");
        await fetchLoggedInUserRole();
        console.log("fetchLoggedInUserRole END");

        // Alert the user about successful login
        NotificationManager.success(`Uspješno ste se ulogovali`);

        setTimeout(() => {
          window.location.href = "/";
        }, 1000);
      } else {
        // Handle unsuccessful login (no tokens received)
        console.log("Login failed");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Handle 401 Unauthorized error

        setErrorMessage(
          "Lozinka ili mejl nisu ispravni ili nalog nije verifikovan"
        );
        resetForm();
      } else {
        // Handle other errors
        setErrorMessage("Neuspješna prijava. Molimo pokušajte ponovo.");
        resetForm();
      }
    }
  };

  return (
    <div className="login-container">
      <Container
        className="my-5"
        style={{ paddingBottom: "6.5%", paddingTop: "2%" }}
      >
        <Formik
          initialValues={{
            username: "",
            password: "",
          }}
          onSubmit={handleSubmit}
        >
          {({ values, resetForm, setSubmitting }) => {
            return (
              <Row className="justify-content-center">
                <Col xs={12} md={8} lg={6}>
                  <h1 className="text-center mb-4">Uloguj se</h1>

                  <Form
                    onKeyDown={(e) => {
                      if (e.key == "Enter") {
                        handleSubmit(values, { resetForm });
                      }
                    }}
                  >
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <Field
                        type="text"
                        name="username"
                        id="email"
                        className="form-control"
                        placeholder="Unesite email..."
                      />
                    </div>
                    <div className="password-input">
                      <label htmlFor="password" className="form-label">
                        Lozinka
                      </label>
                      <div style={{ position: "relative" }}>
                        <Field
                          required
                          type={showPassword ? "text" : "password"}
                          name="password"
                          id="password"
                          placeholder="Unesite lozinku..."
                          className="form-control"
                        />
                        {/* Password visibility toggle */}
                        <span
                          onClick={() => setShowPassword(!showPassword)}
                          className="password-toggle"
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "10px",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                          }}
                        >
                          {showPassword ? <FiEyeOff /> : <FiEye />}
                        </span>
                      </div>
                    </div>

                    {errorMessage !== "" && (
                      <p
                        style={{
                          color: "orange",
                          margin: "0",
                          paddingBottom: "10px",
                        }}
                      >
                        {errorMessage}
                      </p>
                    )}
                    <Row>
                      <Col lg={5}>
                        <Button
                          style={{
                            color: "white",
                            backgroundColor: "black",
                            border: "none",
                            marginTop: "10px",
                          }}
                          className="register-btn"
                          type="submit"
                          variant="primary"
                        >
                          Potvrdi
                        </Button>
                      </Col>
                      <Col style={{ padding: "15px" }} lg={4}>
                        <a
                          style={{
                            textDecoration: "underline",
                            color: "black",
                            fontSize: "15px",
                          }}
                          href="/zaboravljena-lozinka"
                        >
                          Zaboravili ste lozinku?
                        </a>
                      </Col>
                      <Col style={{ padding: "15px" }} lg={3}>
                        <a
                          style={{
                            textDecoration: "underline",
                            color: "black",
                            fontSize: "15px",
                          }}
                          href="/register"
                        >
                          Registrujte se
                        </a>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            );
          }}
        </Formik>
        <NotificationContainer />
      </Container>
    </div>
  );
};

export default Login;
