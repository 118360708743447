export function isSerbianCyrillic(text) {
  // Regex to match characters in the Serbian Cyrillic Unicode range plus spaces and common punctuation
  const cyrillicRegex = /^[\u0400-\u04FF\s.,!?()]+$/;

  // Debug: Print each character and its Unicode code point

  // Test the text against the regex
  const result = cyrillicRegex.test(text);
  return result;
}
