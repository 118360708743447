import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./styles/header.css";
import "./styles/home-page.css";
import "./styles/footer.css";
import "./styles/register.css";
import "./styles/userprofile.css";
import "./styles/predajcitulju.css";
import "./styles/formazapopunjavanje.css";
import "./styles/umrlica.css";
import "./styles/poslednji-pozdrav.css";
import "./styles/login.css";
import "./styles/pomen.css";
import "./styles/responsive.css";
import AOS from "aos";
import "aos/dist/aos.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-notifications/lib/notifications.css";
AOS.init();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
