import React from "react";

const ProcedureInCaseOfDeath = () => {
  return (
    <div className="container" style={{ paddingTop: "140px" }}>
      <h2 className="text-center">Postupci u slučaju smrti</h2>
      <h4 className="mt-5">Postupanje u slučaju smrti u kući</h4>
      <p>Ako se smrt dogodi u kući, procedura je kako slijedi:</p>
      <p>
        Potrebno je pozvati Službu hitne pomoći da potvrdi smrt. Nakon što
        Služba hitne pomoći potvrdi smrt i izda smrtovnicu, treba kontaktirati
        pogrebnu službu.
      </p>
      <p>
        Potrebno je utvrditi vrijeme sahrane, odabrati opremu za sahranu u
        Pogrebnoj službi, razgovarati o svim detaljima sa upravnikom groblja o
        lokaciji sahrane, kao i o svim drugim aspektima sahrane. Sve dokumente
        vezane za prijavu smrti treba odnijeti u lokalnu jedinicu MUP-a zbog
        upisa u Knjigu umrlih i izdavanja potvrde. Predati račun za pogrebnu
        opremu i usluge, ako je pokojnik bio osiguran, zajedno sa ličnom kartom
        i posljednjim primanjem pokojnika u Fond za penzijsko i invalidsko
        osiguranje za ostvarivanje prava.
      </p>
      <h4 className="mt-5">Smrt u medicinskoj ustanovi</h4>
      <p className="mt-3">
        U slučaju smrti unutar medicinske ustanove, procedura je sljedeća:
      </p>
      <p>
        Pribaviti od odjeljenja zdravstvene ustanove u kojoj je pokojnik
        preminuo potrebne informacije za prijavu smrti. Sa smrtovnicom se
        obratiti osoblju mrtvačnice u bolnici. Ostaviti odjeću za sahranu
        pokojnika u mrtvačnici. U pogrebnoj službi utvrditi datum i vrijeme
        sahrane, odabrati pogrebnu opremu i dogovoriti sve detalje oko
        preuzimanja, transporta i sahrane pokojnika. Na groblju, gdje će se
        obaviti sahrana, dogovoriti sve detalje sa nadležnom službom o mjestu
        sahrane, mjestu gdje će pokojnik biti sahranjen i sve druge detalje
        vezane za sahranu, te upis pokojnika u Registar umrlih. Predati račun za
        pogrebnu opremu i usluge, ako je pokojnik bio osiguran, zajedno sa
        ličnom kartom pokojnika Fond za penzijsko i invalidsko osiguranje za
        ostvarivanje prava.
      </p>
      <h4 className="mt-5">Postupci u slučaju sumnje na neprirodnu smrt</h4>
      <p className="mt-3">
        Ako smrt nastupi u slučaju osobe koja je živjela sama ili se u trenutku
        smrti nalazila sama, obavezno je pozvati policiju radi utvrđivanja smrti
        i okolnosti pod kojima je do smrti došlo, kako bi se ustanovilo je li
        smrt bila prirodna ili nasilna. Isto tako, potrebno je postupiti i u
        slučaju nasilne smrti, ubistva ili samoubistva.
      </p>
    </div>
  );
};

export default ProcedureInCaseOfDeath;
