import React from "react";
import { Container, Row, Col, Form, Button, Image } from "react-bootstrap";
import profileIcon from "../assets/user2.png";

const User = () => {
  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.setItem("userRole", "");
    window.location.href = "/";
  };
  const userName = localStorage.getItem("loggedInUserName");

  return (
    <Container style={{ marginTop: "2%" }}>
      <Row className="justify-content-center">
        <Col xs={12} className="text-center mb-3 p-4 bg-light">
          <Image src={profileIcon} height="100" width="100" roundedCircle />
        </Col>
        <Row>
          <Form>
            <Form.Group controlId="formUserEmail">
              <Form.Label className="text-center">
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {userName}
                </span>
              </Form.Label>{" "}
              <Button
                style={{
                  background: "darkred",
                  border: "none",
                  float: "right",
                }}
                onClick={logout}
              >
                Izloguj se
              </Button>
            </Form.Group>
          </Form>
        </Row>
      </Row>
    </Container>
  );
};

export default User;
