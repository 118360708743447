import React from "react";
import { Container, Row } from "react-bootstrap";

function PreviewQrPomen() {
  return (
    <Container fluid>
      <Row lg={12} md={12} sm={12}>
        <div
          style={{ height: "auto", marginTop: "20px" }}
          className="poslednji-pozdrav"
        >
          <div className="title-container">
            <img
              style={{ width: "100px", height: "100px", padding: "0" }}
              src={require("../assets/user2.png")}
              alt="x"
            />
            <h4 style={{ fontSize: "20px" }}>Ime Prezime</h4>
          </div>
        </div>
      </Row>
    </Container>
  );
}

export default PreviewQrPomen;
