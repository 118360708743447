import React, { useState, useEffect } from "react";
import axios from "axios";
import { Col, Row } from "react-bootstrap";
import { API_URL } from "../../config";
const PricingList = () => {
  const [prices, setPrices] = useState([]);

  function getPrices() {
    const apiUrl = `${API_URL}/api/prices/`;
    const accessToken = localStorage.getItem("accessToken");

    axios
      .get(apiUrl)
      .then((response) => {
        setPrices(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  useEffect(() => {
    getPrices();
  }, []);
  const handlePriceClick = () => {
    window.location.href = "/predaj-citulju";
  };
  return (
    <div className="container" style={{ paddingTop: "140px" }}>
      <h2 className="text-center pb-5">Cjenovnik</h2>{" "}
      <h3 className="text-center">Umrlice</h3>
      <Row className="d-flex justify-content-center align-items-center mt-5">
        {prices
          .filter((item) => item.type === "umrlica")
          .map((price) => (
            <Col
              style={{ minHeight: "150px" }}
              sm={12}
              lg={4}
              onClick={handlePriceClick}
              className="pricing-card umrlice-card d-flex justify-content-center align-items-center p-3  mt-3 "
            >
              <div className="card-body text-dark">
                <h5 className="card-title">
                  {" "}
                  {(() => {
                    let sizeText;
                    switch (price.size) {
                      case 1:
                        sizeText = "Mali";
                        break;
                      case 2:
                        sizeText = "Srednji";
                        break;
                      case 3:
                        sizeText = "Veliki";
                        break;
                      case 4:
                        sizeText = "Najmanji";
                        break;
                      default:
                        sizeText = "Unknown Size";
                    }
                    return sizeText;
                  })()}{" "}
                  format{" "}
                  {price.type === "umrlica"
                    ? "umrlice"
                    : price.type === "poslednji_pozdrav"
                    ? "posljednjeg pozdrava"
                    : price.type === "pomen"
                    ? "pomena"
                    : "unknown type"}
                </h5>
                <hr></hr>
                <Row>
                  {" "}
                  {price.size !== 4 && (
                    <div style={{ textTransform: "uppercase" }}>
                      {price.size !== 4 && "jedna fotografija:"}
                      <span style={{ fontSize: "20px" }}>
                        {" "}
                        {price.price_single.toFixed(2)}€
                      </span>
                    </div>
                  )}
                  <div style={{ textTransform: "uppercase" }}>
                    {price.size !== 4 && "više fotografija:"}
                    <span style={{ fontSize: "20px" }}>
                      {price.price_multiple.toFixed(2)}
                    </span>{" "}
                    €
                  </div>
                </Row>
              </div>
            </Col>
          ))}{" "}
      </Row>{" "}
      <h3 className="text-center mt-5">Posljednji pozdravi</h3>
      <Row className="d-flex justify-content-center align-items-center mt-5">
        {prices
          .filter((item) => item.type === "poslednji_pozdrav")
          .map((price) => (
            <Col
              style={{ minHeight: "150px" }}
              sm={12}
              lg={4}
              onClick={handlePriceClick}
              className=" pricing-card  umrlice-card d-flex justify-content-center align-items-center p-3  mt-3 "
            >
              <div className="card-body text-dark">
                <h5 className="card-title">
                  {" "}
                  {(() => {
                    let sizeText;
                    switch (price.size) {
                      case 1:
                        sizeText = "Mali";
                        break;
                      case 2:
                        sizeText = "Srednji";
                        break;
                      case 3:
                        sizeText = "Veliki";
                        break;
                      case 4:
                        sizeText = "Najmanji";
                        break;
                      default:
                        sizeText = "Unknown Size";
                    }
                    return sizeText;
                  })()}{" "}
                  format{" "}
                  {price.type === "umrlica"
                    ? "umrlice"
                    : price.type === "poslednji_pozdrav"
                    ? "posljednjeg pozdrava"
                    : price.type === "pomen"
                    ? "pomena"
                    : "unknown type"}
                </h5>
                <hr></hr>
                <Row>
                  {" "}
                  {price.size !== 4 && (
                    <div style={{ textTransform: "uppercase" }}>
                      {price.size !== 4 && "jedna fotografija:"}
                      <span style={{ fontSize: "20px" }}>
                        {" "}
                        {price.price_single.toFixed(2)}€
                      </span>
                    </div>
                  )}
                  <div style={{ textTransform: "uppercase" }}>
                    {price.size !== 4 && "više fotografija:"}
                    <span style={{ fontSize: "20px" }}>
                      {price.price_multiple.toFixed(2)}
                    </span>{" "}
                    €
                  </div>
                </Row>
              </div>
            </Col>
          ))}{" "}
      </Row>
      <h3 className="text-center mt-5">Pomeni</h3>
      <Row className="d-flex justify-content-center align-items-center mt-5">
        {prices
          .filter((item) => item.type === "pomen")
          .map((price) => (
            <Col
              style={{ minHeight: "150px" }}
              sm={12}
              lg={4}
              onClick={handlePriceClick}
              className="pricing-card  umrlice-card d-flex justify-content-center align-items-center p-3  mt-3 "
            >
              <div className="card-body text-dark">
                <h5 className="card-title">
                  {" "}
                  {(() => {
                    let sizeText;
                    switch (price.size) {
                      case 1:
                        sizeText = "Mali";
                        break;
                      case 2:
                        sizeText = "Srednji";
                        break;
                      case 3:
                        sizeText = "Veliki";
                        break;
                      case 4:
                        sizeText = "Najmanji";
                        break;
                      default:
                        sizeText = "Unknown Size";
                    }
                    return sizeText;
                  })()}{" "}
                  format{" "}
                  {price.type === "umrlica"
                    ? "umrlice"
                    : price.type === "poslednji_pozdrav"
                    ? "posljednjeg pozdrava"
                    : price.type === "pomen"
                    ? "pomena"
                    : "unknown type"}
                </h5>
                <hr></hr>
                <Row>
                  {" "}
                  {price.size !== 4 && (
                    <div style={{ textTransform: "uppercase" }}>
                      {price.size !== 4 && "jedna fotografija:"}
                      <span style={{ fontSize: "20px" }}>
                        {" "}
                        {price.price_single.toFixed(2)}€
                      </span>
                    </div>
                  )}
                  <div style={{ textTransform: "uppercase" }}>
                    {price.size !== 4 && "više fotografija:"}
                    <span style={{ fontSize: "20px" }}>
                      {price.price_multiple.toFixed(2)}
                    </span>{" "}
                    €
                  </div>
                </Row>
              </div>
            </Col>
          ))}{" "}
      </Row>
    </div>
  );
};

export default PricingList;
