import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import { API_URL } from "../config";

import ShareButtons from "./ShareButtons";
import { isSerbianCyrillic } from "../submitFormUtils/isSerbianCyrlic";
function QuarterPomen({ post, modalIsOpen }) {
  const [counter, setCounter] = useState(post.counter);
  const [imageSrc, setImageSrc] = useState(require("../assets/candle.png"));
  const deletePosts = async () => {
    const accessToken = localStorage.getItem("accessToken");
    try {
      const response = await axios.delete(`${API_URL}/api/posts/${post.id}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      window.location.href = "/";
    } catch (error) {
      console.log("Error fetching posts:", error);
    }
  };

  const handleCandleClick = () => {
    // Toggle between two image sources
    if (imageSrc === require("../assets/candle.png")) {
      setImageSrc(require("../assets/candleColor.png"));
    } else {
      setImageSrc(require("../assets/candle.png"));
    }
    increaseCounter();
  };

  const increaseCounter = async () => {
    try {
      setCounter(post.counter + 1);
      await axios.post(`${API_URL}/api/posts/${post.id}/counter/up/`, {});
    } catch (error) {
      console.log("Error fetching posts:", error);
    }
  };
  const userRole = localStorage.getItem("userRole");
  return (
    <Container
      data-aos="fade-up"
      style={{
        border: "5px double black",
        minHeight: "147.5px",
        height: modalIsOpen ? "auto" : "147.5px",
      }}
      fluid
      className="post"
    >
      <Row lg={12} md={12} sm={12}>
        {" "}
        <span
          style={{
            fontStyle: "italic",
            border: "none",
            borderTop: "none",
            textAlign: "right",
            backgroundColor: "white",
            color: "gray",
            fontSize: "smaller",
            paddingRight: "0.5vh",
          }}
        >
          {isSerbianCyrillic(post.name) ? "помен" : post.type}
        </span>
        <h4
          style={{
            width: "100%",
            fontSize: "20px",
            padding: "40px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          {post.name.includes("\n")
            ? post.name
                .split("\n")
                .slice(0, 2)
                .map((line, index, array) => (
                  <React.Fragment key={index}>
                    {line}
                    {index < array.length - 1 && <br />}
                  </React.Fragment>
                ))
            : post.name}
        </h4>
        {modalIsOpen && <p style={{ textAlign: "left" }}>{post.main_text}</p>}
        {modalIsOpen && <p style={{ width: "100%" }}>{post.text_bottom}</p>}
        {modalIsOpen && (userRole === "admin" || userRole === "superadmin") && (
          <Col>
            <button
              style={{ marginBottom: "2%" }}
              className="btn btn-danger"
              onClick={() => deletePosts()}
            >
              Obriši
            </button>
          </Col>
        )}
      </Row>
      {modalIsOpen && <ShareButtons id={post.id} />}
    </Container>
  );
}

export default QuarterPomen;
