import React from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import axios from "axios";
import { API_URL } from "../config";

const FormaZaPopunjavanje = () => {
  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("text_top", values.top_text);
    formData.append("main_text", values.main_text);
    formData.append("icon", values.icon);
    formData.append("image", values.image);
    formData.append("checkbox", values.checkbox);
    formData.append("text_bottom", values.bottom_text);
    formData.append("type", values.checkbox);
    formData.append("size", values.size);
    try {
      const response = await axios.post(`${API_URL}/api/posts/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Formik
      initialValues={{
        name: "",
        top_text: "",
        main_text: "",
        icon: "",
        image: null,
        checkbox: "",
        size: "",
        bottom_text: "",
      }}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, setFieldValue, values, handleChange }) => (
        <Form
          className="FormaZaPopunjavanje-form"
          onSubmit={handleSubmit}
          style={{
            margin: "0 auto",
            marginTop: "3%",
            maxWidth: "500px",
            padding: "20px",
            backgroundColor: "#f5f5f5",
            borderRadius: "5px",
          }}
        >
          <div>
            <Row>
              <Col sm={6}>
                <Form.Group controlId="formName">
                  <Form.Label className="FormaZaPopunjavanje-label">
                    Name
                  </Form.Label>
                  <Form.Control
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    className="FormaZaPopunjavanje-input"
                    style={{ marginTop: "8px" }}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group controlId="formTopText">
                  <Form.Label className="FormaZaPopunjavanje-label">
                    Top Text
                  </Form.Label>
                  <Form.Control
                    name="top_text"
                    onChange={handleChange}
                    value={values.top_text}
                    className="FormaZaPopunjavanje-input"
                    style={{ marginTop: "8px" }}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group controlId="formMainText">
              <Form.Label className="FormaZaPopunjavanje-label">
                Main Text
              </Form.Label>
              <Form.Control
                name="main_text"
                as="textarea"
                rows={4}
                onChange={handleChange}
                value={values.main_text}
                className="FormaZaPopunjavanje-textarea"
                style={{ marginTop: "8px" }}
              />
            </Form.Group>

            <Form.Group controlId="formBottomText">
              <Form.Label className="FormaZaPopunjavanje-label">
                Bottom Text
              </Form.Label>
              <Form.Control
                name="bottom_text"
                as="textarea"
                rows={4}
                onChange={handleChange}
                value={values.bottom_text}
                className="FormaZaPopunjavanje-textarea"
                style={{ marginTop: "8px" }}
              />
            </Form.Group>

            <Row>
              <Col sm={6}>
                <Form.Group controlId="formCheckbox">
                  <Form.Label className="FormaZaPopunjavanje-label">
                    Checkbox
                  </Form.Label>
                  <div className="radio-button-box">
                    <Form.Check
                      name="checkbox"
                      type="radio"
                      label="Umrlica"
                      value="umrlica"
                      onChange={handleChange}
                      checked={values.checkbox === "umrlica"}
                      className="FormaZaPopunjavanje-checkbox-label"
                      style={{ marginTop: "8px" }}
                    />
                    <Form.Check
                      name="checkbox"
                      type="radio"
                      label="Posljednji pozdrav"
                      value="poslednji_pozdrav"
                      onChange={handleChange}
                      checked={values.checkbox === "poslednji_pozdrav"}
                      className="FormaZaPopunjavanje-checkbox-label"
                      style={{ marginTop: "8px" }}
                    />
                    <Form.Check
                      name="checkbox"
                      type="radio"
                      label="Pomen"
                      value="pomen"
                      onChange={handleChange}
                      checked={values.checkbox === "pomen"}
                      className="FormaZaPopunjavanje-checkbox-label"
                      style={{ marginTop: "8px" }}
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formVelicina">
                  <Form.Label className="FormaZaPopunjavanje-label">
                    Velicina
                  </Form.Label>
                  <Form.Check
                    name="size"
                    type="radio"
                    label="Mala"
                    value="1"
                    onChange={(event) => {
                      setFieldValue("size", event.target.value);
                    }}
                    checked={values.size === "1"}
                    className="FormaZaPopunjavanje-checkbox-label"
                    style={{ marginTop: "8px" }}
                  />
                  <Form.Check
                    name="size"
                    type="radio"
                    label="Srednja"
                    value="2"
                    onChange={(event) => {
                      setFieldValue("size", event.target.value);
                    }}
                    checked={values.size === "2"}
                    className="FormaZaPopunjavanje-checkbox-label"
                    style={{ marginTop: "8px" }}
                  />
                  <Form.Check
                    name="size"
                    type="radio"
                    label="Velika"
                    value="3"
                    onChange={(event) => {
                      setFieldValue("size", event.target.value);
                    }}
                    checked={values.size === "3"}
                    className="FormaZaPopunjavanje-checkbox-label"
                    style={{ marginTop: "8px" }}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group controlId="formIcon">
                  <Form.Label className="FormaZaPopunjavanje-label">
                    Select Icon
                  </Form.Label>
                  <Form.Control
                    name="icon"
                    as="select"
                    onChange={handleChange}
                    value={values.icon}
                    className="FormaZaPopunjavanje-select"
                    style={{ marginTop: "8px" }}
                  >
                    <option value="">Select an icon</option>
                    <option value="icon1">Icon 1</option>
                    <option value="icon2">Icon 2</option>
                    <option value="icon3">Icon 3</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formImage">
                  <Form.Label className="FormaZaPopunjavanje-label">
                    Image
                  </Form.Label>
                  <Form.Control
                    name="image"
                    type="file"
                    accept="image/*"
                    onChange={(event) => {
                      setFieldValue("image", event.currentTarget.files[0]);
                    }}
                    className="FormaZaPopunjavanje-input"
                    style={{ marginTop: "8px" }}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Button
              className="FormaZaPopunjavanje-submit"
              variant="primary"
              type="submit"
              style={{ marginTop: "16px" }}
            >
              Submit
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FormaZaPopunjavanje;
