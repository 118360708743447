import React, { useState } from "react";
import { Container, Row, Button } from "react-bootstrap";
import axios from "axios";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {API_URL} from "../config"

const AdsTab = () => {
  const [selectedMediumImage, setSelectedMediumImage] = useState([]);
  const [selectedSmallImage, setSelectedSmallImage] = useState([]);
  const [selectedTinyImage, setSelectedTinyImage] = useState([]);
  const uploadMediumImg = async (e) => {
    const uploadedImagePaths = [];
    e.preventDefault();
    if (selectedMediumImage && selectedMediumImage.length > 0) {
      for (const imageFile of selectedMediumImage) {
        try {
          const response = await axios.post(
            `${API_URL}/api/posts/upload_image/`,
            { image: imageFile },
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              },
            }
          );

          const imagePath = response.data.path;
          // Check if the imagePath already exists in the uploadedImagePaths
          const existingImageIndex = uploadedImagePaths.findIndex(
            (path) => path === imagePath
          );

          if (existingImageIndex !== -1) {
            // Replace the existing image with the new one
            uploadedImagePaths[existingImageIndex] = imagePath;
          } else {
            // If not found, add the new image path to the array
            uploadedImagePaths.push(imagePath);
          }
        } catch (error) {
          console.error(error);
        }
      }
    }

    axios
      .post(
        `${API_URL}/api/ads/`,
        {
          size: "large",
          image: uploadedImagePaths[0],
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      )
      .then(function (response) {
        NotificationManager.success("Srednja reklama izmijenjena");
      })
      .catch(function (error) {
        NotificationManager.error("Greska");
      });
  };

  const handleMediumImageChange = (event) => {
    const newImage = event.target.files[0];
    setSelectedMediumImage([...selectedMediumImage, newImage]);
  };

  // -------------------- Najmanja reklama ---------------------//

  const uploadSmallImg = async (e) => {
    const uploadedImagePaths = [];
    e.preventDefault();
    if (selectedSmallImage && selectedSmallImage.length > 0) {
      for (const imageFile of selectedSmallImage) {
        try {
          const response = await axios.post(
            `${API_URL}/api/posts/upload_image/`,
            { image: imageFile },
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              },
            }
          );

          const imagePath = response.data.path;
          // Check if the imagePath already exists in the uploadedImagePaths
          const existingImageIndex = uploadedImagePaths.findIndex(
            (path) => path === imagePath
          );

          if (existingImageIndex !== -1) {
            // Replace the existing image with the new one
            uploadedImagePaths[existingImageIndex] = imagePath;
          } else {
            // If not found, add the new image path to the array
            uploadedImagePaths.push(imagePath);
          }
        } catch (error) {
          console.error(error);
        }
      }
    }

    axios
      .post(
        `${API_URL}/api/ads/`,
        {
          size: "medium",
          image: uploadedImagePaths[0],
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      )
      .then(function (response) {
        NotificationManager.success("Mala reklama izmijenjena");
      })
      .catch(function (error) {
        NotificationManager.error("Greska");
      });
  };

  const handleSmallImageChange = (event) => {
    const newImage = event.target.files[0];
    setSelectedSmallImage([...selectedSmallImage, newImage]);
  };
  // -----------MALA REKLAMA ----------------------- //

  const uploadTinyImg = async (e) => {
    const uploadedImagePaths = [];
    e.preventDefault();
    if (selectedTinyImage && selectedTinyImage.length > 0) {
      for (const imageFile of selectedTinyImage) {
        try {
          const response = await axios.post(
            `${API_URL}/api/posts/upload_image/`,
            { image: imageFile },
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              },
            }
          );

          const imagePath = response.data.path;
          // Check if the imagePath already exists in the uploadedImagePaths
          const existingImageIndex = uploadedImagePaths.findIndex(
            (path) => path === imagePath
          );

          if (existingImageIndex !== -1) {
            // Replace the existing image with the new one
            uploadedImagePaths[existingImageIndex] = imagePath;
          } else {
            // If not found, add the new image path to the array
            uploadedImagePaths.push(imagePath);
          }
        } catch (error) {
          console.error(error);
        }
      }
    }

    axios
      .post(
        `${API_URL}/api/ads/`,
        {
          size: "small",
          image: uploadedImagePaths[0],
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      )
      .then(function (response) {
        NotificationManager.success("Najmanja reklama izmijenjena");
      })
      .catch(function (error) {
        console.log(error);
        NotificationManager.error("Greska");
      });
  };

  const handleTinyImageChange = (event) => {
    const newImage = event.target.files[0];
    setSelectedTinyImage([...selectedTinyImage, newImage]);
  };

  return (
    <Container>
      <Row>
        <form onSubmit={uploadMediumImg}>
          <div className="field d-flex flex-column mt-3">
            <label>Srednja reklama (520x650px)</label>
            <input onChange={handleMediumImageChange} type="file" />
            <hr></hr>
          </div>

          <Button type="submit" style={{ borderRadius: "8px" }}>
            Izmijeni srednju reklamu
          </Button>
        </form>
        <form onSubmit={uploadSmallImg}>
          <div className="field d-flex flex-column mt-3">
            <label>Mala reklama (520x315px)</label>
            <input onChange={handleSmallImageChange} type="file" />
            <hr></hr>
          </div>

          <Button type="submit" style={{ borderRadius: "8px" }}>
            Izmijeni malu reklamu
          </Button>
        </form>
        <form onSubmit={uploadTinyImg}>
          <div className="field d-flex flex-column mt-3">
            <label>Najmanja reklama (520x147px)</label>
            <input onChange={handleTinyImageChange} type="file" />
            <hr></hr>
          </div>

          <Button type="submit" style={{ borderRadius: "8px" }}>
            Izmijeni najmanju reklamu
          </Button>
        </form>
      </Row>{" "}
      <NotificationContainer animation={false} />
    </Container>
  );
};

export default AdsTab;
