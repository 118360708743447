import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the styles
function PreviewLgUmrlica({ multiple }) {
  const modalStyle = {
    border: "15px groove black",
    backgroundColor: "white",
    marginTop: "20px",
  };
  return (
    <Container style={modalStyle}>
      <Row style={{ paddingTop: "1%" }}>
        <p style={{ paddingRight: "10%" }}>Gornji tekst</p>
      </Row>
      <Col
        style={{ marginTop: "30px" }}
        className="d-flex justify-content-center"
      >
        <img
          id="umrlica-icon"
          style={{
            height: "50px",
            width: "50px",
            border: "none",
          }}
          src={require("../assets/krst.png")}
          alt=""
        />
      </Col>
      <Row style={{ paddingTop: "2%" }}>
        <Col className="d-flex justify-content-around"></Col>

        <Col className="d-flex justify-content-around">
          {multiple && (
            <Carousel
              showArrows={false}
              infiniteLoop={true}
              autoPlay={true}
              interval={1000}
            >
              <img
                style={{
                  width: "150px",
                  height: "150px",
                  border: "3px solid black",
                }}
                src={require("../assets/user2.png")}
                alt={`Image`}
              />
              <img
                style={{
                  width: "150px",
                  height: "150px",
                  border: "3px solid black",
                }}
                src={require("../assets/user2.png")}
                alt={`Image`}
              />
              <img
                style={{
                  width: "150px",
                  height: "150px",
                  border: "3px solid black",
                }}
                src={require("../assets/user2.png")}
                alt={`Image`}
              />
            </Carousel>
          )}
          {!multiple && (
            <img
              style={{
                width: "150px",
                height: "150px",
                border: "3px solid black",
              }}
              src={require("../assets/user2.png")}
              alt={`Image`}
            />
          )}
        </Col>
        <Col className="d-flex justify-content-around"></Col>
      </Row>
      <Row style={{ paddingTop: "2%" }}>
        <Col
          style={{ fontWeight: "bold", letterSpacing: "1.2px" }}
          className="d-flex justify-content-around"
        >
          Ime Prezime
        </Col>
      </Row>
      <Col className="d-flex justify-content-around">
        <span style={{ textAlign: "center" }}>datum - datum</span>
      </Col>
      <Row>
        <p style={{ padding: "2%" }}>
          Glavni tekst glavni tekst glavni tekst glavni tekst glavni tekst
          glavni tekst{" "}
        </p>
      </Row>

      <hr></hr>
      <Row style={{ padding: "2%" }}>
        <b>
          <h5 id="sakrij">Ožalošćeni:</h5>
          <p id="sakrij">Ožalošćeni 1, Ožalošćeni 2</p>
        </b>
      </Row>
    </Container>
  );
}

export default PreviewLgUmrlica;
