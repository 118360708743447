import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Image } from "react-bootstrap";
import axios from "axios"; // Import Axios
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import showPasswordIcon from "../assets/show-regular-24.png";
import hidePasswordIcon from "../assets/show-solid-24.png";
import { API_URL } from "../config";

const ChangeUserPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState(null);

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setConfirmPasswordError(null);
  };

  const toggleShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };

  const toggleShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };

  const toggleShowPassword3 = () => {
    setShowPassword3(!showPassword3);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setConfirmPasswordError("Lozinke se ne poklapaju!");
      return;
    } else if (newPassword.length < 8) {
      setNewPasswordError("Minimum 8 karaktera!");
      return;
    } else if (!/[A-Z]/.test(newPassword)) {
      setNewPasswordError("Mora biti jedno veliko slovo!");
      return;
    } else if (!/[0-9]/.test(newPassword)) {
      setNewPasswordError("Mora biti jedan broj!");
      return;
    }

    updatePassword();
  };

  const updatePassword = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      if (!accessToken) {
        console.log("Access token not found. Please authenticate.");
        return;
      }

      /*PROVJERI */
      const apiUrl = `${API_URL}/api/reset_password/?token=${accessToken}`;
      const data = {
        new_password: newPassword,
      };

      const response = await axios.post(apiUrl, data, {
        headers: {
          "Content-Type": "multipart/form-data",

          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        console.log("Password updated successfully");
        // Clear the input fields
        NotificationManager.success(`Lozinka uspješno izmijenjena`);

        setNewPassword("");
        setConfirmPassword("");
      } else {
        // Handle unauthorized or error response
        console.log("Error updating password:", response.status);
      }
    } catch (error) {
      console.log("Error updating password:", error);
    }
  };

  return (
    <Container style={{ marginTop: "2%" }}>
      <Row className="justify-content-center">
        <h3>Promijeni lozinku</h3>
        <Col xs={12}>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formNewPassword">
              <Form.Label
                className="text-center"
                style={{ paddingTop: "10px" }}
              >
                Unesite novu lozinku
              </Form.Label>
              <div className="position-relative">
                <Form.Control
                  required
                  type={showPassword2 ? "text" : "password"}
                  placeholder="Nova lozinka"
                  value={newPassword}
                  onChange={handleNewPasswordChange}
                />
                <div
                  className="position-absolute top-50 end-0 translate-middle"
                  style={{
                    cursor: "pointer",
                    width: "30px",
                    height: "30px",
                    zIndex: 1,
                  }}
                  onClick={toggleShowPassword2}
                >
                  {showPassword2 ? (
                    <Image src={hidePasswordIcon} height="20" width="20" />
                  ) : (
                    <Image src={showPasswordIcon} height="20" width="20" />
                  )}
                </div>
              </div>
              {newPasswordError && (
                <Form.Text className="text-danger">
                  {newPasswordError}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group controlId="formConfirmPassword">
              <Form.Label
                className="text-center"
                style={{ paddingTop: "10px" }}
              >
                Potvrdite novu lozinku
              </Form.Label>
              <div className="position-relative">
                <Form.Control
                  required
                  type={showPassword3 ? "text" : "password"}
                  placeholder="Potvrdi lozinku"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                />
                <div
                  className="position-absolute top-50 end-0 translate-middle"
                  style={{
                    cursor: "pointer",
                    width: "30px",
                    height: "30px",
                    zIndex: 1,
                  }}
                  onClick={toggleShowPassword3}
                >
                  {showPassword3 ? (
                    <Image src={hidePasswordIcon} height="20" width="20" />
                  ) : (
                    <Image src={showPasswordIcon} height="20" width="20" />
                  )}
                </div>
              </div>
              {confirmPasswordError && (
                <Form.Text className="text-danger">
                  {confirmPasswordError}
                </Form.Text>
              )}
            </Form.Group>
            <div className="text-center">
              <Button className="user-profile-btn" type="submit">
                Promijeni lozinku
              </Button>
            </div>
          </Form>
          <hr />
        </Col>
      </Row>
      <NotificationContainer animation={false} />
    </Container>
  );
};

export default ChangeUserPassword;
