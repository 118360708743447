import React from "react";
import { Form, Button, Row } from "react-bootstrap";
import { Formik } from "formik";
import axios from "axios";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { API_URL } from "../config";

const CreateAdmin = () => {
  const handleSubmit = async (values, formikBag) => {
    const formData = new FormData();
    formData.append("first_name", values.first_name);
    formData.append("last_name", values.last_name);
    formData.append("email", values.email);
    formData.append("password", values.password);
    try {
      const response = await axios.post(
        `${API_URL}/api/admin/add/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      NotificationManager.success(`Uspješno kreiran nalog admina`);
      formikBag.resetForm();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Formik
      initialValues={{
        first_name: "",
        last_name: "",
        email: "",
        password: "",
      }}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, values, handleChange }) => (
        <Form
          className="FormaZaPopunjavanje-form create-admin"
          onSubmit={handleSubmit}
          style={{
            margin: "0 auto",
            marginTop: "3%",
            maxWidth: "500px",
            padding: "20px",
            backgroundColor: "#f5f5f5",
            borderRadius: "5px",
          }}
        >
          <div>
            <p>Tip: Admin</p>
            <Row>
              <Form.Group controlId="formAdminFirstName">
                <h6>Ime</h6>
                <Form.Control
                  required
                  placeholder="Unesite ime..."
                  name="first_name"
                  onChange={handleChange}
                  value={values.first_name}
                  className="FormaZaPopunjavanje-input"
                  style={{ marginTop: "8px" }}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group controlId="formAdminLastName">
                <h6>Prezime</h6>
                <Form.Control
                  required
                  placeholder="Unesite prezime..."
                  name="last_name"
                  onChange={handleChange}
                  value={values.last_name}
                  className="FormaZaPopunjavanje-input"
                  style={{ marginTop: "8px" }}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group controlId="formAdminEmail">
                <h6>Email</h6>
                <Form.Control
                  required
                  placeholder="Unesite e-mail adresu..."
                  name="email"
                  onChange={handleChange}
                  value={values.email}
                  className="FormaZaPopunjavanje-input"
                  style={{ marginTop: "8px" }}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group controlId="formAdminPassword">
                <h6>Lozinka</h6>
                <Form.Control
                  required
                  placeholder="Unesite lozinku..."
                  type="password"
                  name="password"
                  onChange={handleChange}
                  value={values.password}
                  className="FormaZaPopunjavanje-input"
                  style={{ marginTop: "8px" }}
                />
              </Form.Group>
            </Row>
            <Button
              className="FormaZaPopunjavanje-submit"
              variant="primary"
              type="submit"
              style={{ marginTop: "16px" }}
            >
              Potvrdi
            </Button>
          </div>
          <NotificationContainer />
        </Form>
      )}
    </Formik>
  );
};

export default CreateAdmin;
