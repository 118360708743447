import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import * as Yup from "yup";
import { FiEye, FiEyeOff } from "react-icons/fi"; // Import eye icons
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { API_URL } from "../config";

const RegisterPage = () => {
  const initialValues = {
    email: "",
    first_name: "",
    last_name: "",
    username: "",
    password: "",
    confirm_password: "",
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Neispravan email format")
      .required("Email je obavezan"),
    first_name: Yup.string().required("Ime je obavezno"),
    last_name: Yup.string().required("Prezime je obavezno"),
    password: Yup.string()
      .min(8, "Lozinka mora imati najmanje 8 karaktera")
      .matches(/[A-Z]/, "Lozinka mora da sadrži barem jedno veliko slovo")
      .matches(/[0-9]/, "Lozinka mora da sadrži barem jedan broj")
      .required("Lozinka je obavezna"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Lozinke se ne podudaraju")
      .required("Potvrda lozinke je obavezna"),
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    axios
      .post(`${API_URL}/api/register/`, values)
      .then((response) => {
        resetForm();
        setSubmitting(false);
        NotificationManager.success(`Registracija uspješna!`);
        setTimeout(() => {
          window.location.href = "/register-success";
        }, 1000);
      })
      .catch((error) => {
        setSubmitting(false);
        if (error && error.response.status === 400) {
          NotificationManager.error(`Vec postoji korisnik sa ovim e-mailom.`);
        } else {
          NotificationManager.error(`Nesto nije u redu.`);
        }
      });
  };

  return (
    <Container
      className="my-5"
      style={{ paddingBottom: "6.5%", paddingTop: "100px" }}
    >
      <Row className="justify-content-center">
        <Col xs={12} md={8} lg={6}>
          <h1 className="text-center mb-4">Registruj se</h1>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={true}
            validateOnBlur={true}
            enableReinitialize={true}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, values, resetForm, setSubmitting, isValid }) => (
              <Form
                onKeyDown={(e) => {
                  if (e.key === "Enter" && isValid) {
                    onSubmit(values, { resetForm, setSubmitting });
                  }
                }}
              >
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <Field
                    type="email"
                    name="email"
                    id="email"
                    className="form-control"
                    placeholder="Unesite email..."
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="first_name" className="form-label">
                    Ime
                  </label>
                  <Field
                    type="text"
                    name="first_name"
                    id="first_name"
                    className="form-control"
                    placeholder="Unesite ime..."
                  />
                  <ErrorMessage
                    name="first_name"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="last_name" className="form-label">
                    Prezime
                  </label>
                  <Field
                    type="text"
                    name="last_name"
                    id="last_name"
                    className="form-control"
                    placeholder="Unesite prezime..."
                  />
                  <ErrorMessage
                    name="last_name"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    Lozinka
                  </label>
                  <div style={{ position: "relative" }}>
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      id="password"
                      className="form-control"
                      placeholder="Lozinka..."
                    />
                    <span
                      onClick={() => setShowPassword(!showPassword)}
                      className="password-toggle"
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                    >
                      {showPassword ? <FiEyeOff /> : <FiEye />}
                    </span>
                  </div>
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="confirm_password" className="form-label">
                    Potvrdi lozinku
                  </label>
                  <div style={{ position: "relative" }}>
                    <Field
                      type={showConfirmPassword ? "text" : "password"}
                      name="confirm_password"
                      id="confirm_password"
                      className="form-control"
                      placeholder="Potvrdite lozinku..."
                    />
                    <span
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      className="password-toggle"
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                    >
                      {showConfirmPassword ? <FiEyeOff /> : <FiEye />}
                    </span>
                  </div>{" "}
                  <ErrorMessage
                    name="confirm_password"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <Row>
                  {" "}
                  <Col lg={8}>
                    {" "}
                    <Button
                      style={{
                        marginBottom: "2%",
                        color: "white",
                        backgroundColor: "black",
                        border: "none",
                      }}
                      className="register-btn"
                      type="submit"
                      variant="primary"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Potvrđivanje..." : "Potvrdi"}
                    </Button>
                  </Col>
                  <Col sm={12} lg={4}>
                    {" "}
                    <a
                      style={{
                        textDecoration: "underline",
                        color: "black",
                        fontSize: "15px",
                      }}
                      href="/login"
                    >
                      <span className="login-btn-register-page">
                        {" "}
                        Ulogujte se
                      </span>
                    </a>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
      <NotificationContainer />
    </Container>
  );
};

export default RegisterPage;
