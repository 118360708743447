import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import { Col, Container, Row } from "react-bootstrap";
import { UMRLICE_LARAVEL_API_TOKEN } from "../config";
import { API_URL } from "../config";

import countries from '../json/countries.json';

const Payment = () => {
    const [payment, setPayment] = useState(null);
    const [hasProduct, setHasProduct] = useState(false);
    const [product, setProduct] = useState(null);
    let history = useHistory();

    useEffect(() => {
        axios.get(`https://www.umrlice-api.com/api/${UMRLICE_LARAVEL_API_TOKEN}/cart/get?user_id=${localStorage.getItem('userId')}`).then(res => {
            if (res.data.data != null) {
                setHasProduct(true);
                setProduct(res.data.data);
                setTimeout(() => {
                    document.getElementById('main_text').innerHTML = res.data?.data?.main_text ? res.data?.data?.main_text.substr(0, res.data?.data?.main_text.length - 10) + '...' : '';
                    document.getElementById('text_bottom').innerHTML = res.data?.data?.text_bottom ? res.data?.data?.text_bottom.substr(0, res.data?.data?.text_bottom.length - 10) + '...' : '';
                    if (res.data?.data?.birth_date && res.data?.data?.death_date) {
                        document.getElementById('date').innerHTML = res.data?.data?.birth_date + ' / ' + res.data?.data?.death_date
                    }

                    if (res.data?.data?.images.length) {
                        document.getElementById('image').src = API_URL + '/' + res.data?.data?.images[0];
                    }
                    document.getElementById('total').innerText = 'Ukupno: ' + res.data.price + '€';
                    document.getElementById('price').innerText = 'Ukupno: ' + res.data.price + '€';

                    let payment = new window.PaymentJs();
                    payment.init('L7dotQPzSLHG8M8YNKLI', 'number_div', 'cvv_div', function (payment) {
                        payment.setNumberStyle({
                            'border': '1px solid red',
                            'width': '300px',
                            'height': '35px',
                        });
                        payment.setCvvStyle({
                            'border': '1px solid red',
                            'width': '150px',
                            'height': '35px'
                        });
                        payment.numberOn('input', function () {
                            //alert('A number was entered');
                        })
                    });
                    document.getElementById('exp_month').addEventListener('input', function (event) {
                        if (event.target.value.length > 2) {
                            event.target.value = event.target.value.slice(0, 2);
                        }
                    });

                    document.getElementById('exp_year').addEventListener('input', function (event) {
                        if (event.target.value.length > 2) {
                            event.target.value = event.target.value.slice(0, 2);
                        }
                    });

                    document.getElementById('postcode').addEventListener('input', function (event) {
                        if (event.target.value.length > 16) {
                            event.target.value = event.target.value.slice(0, 16);
                        }
                    });

                    document.getElementById('city').addEventListener('input', function (event) {
                        if (event.target.value.length > 50) {
                            event.target.value = event.target.value.slice(0, 50);
                        }
                    });

                    document.getElementById('address').addEventListener('input', function (event) {
                        if (event.target.value.length > 50) {
                            event.target.value = event.target.value.slice(0, 50);
                        }
                    });
                    setPayment(payment);
                }, 500);
            }
        });
    }, []);

    const submitCreditCardInformation = async (e) => {
        e.preventDefault();
        console.log(document.getElementById('address').value.length)
        if (document.getElementById('terms').checked
            && document.getElementById('card_holder').value.length > 0
            && document.getElementById('exp_month').value.length > 0
            && document.getElementById('exp_year').value.length > 0
            && document.getElementById('email').value.length > 0
            && document.getElementById('address').value.length > 0
            && document.getElementById('city').value.length > 0
            && document.getElementById('postcode').value.length > 0
            && document.getElementById('country').value.length > 0
            && document.getElementById('email').value.length > 0
        ) {
            document.getElementById('confirm_button').setAttribute('disabled', true);
            var data = {
                card_holder: document.getElementById('card_holder').value,
                month: document.getElementById('exp_month').value,
                year: document.getElementById('exp_year').value,
                email: document.getElementById('email').value,
                customer: {
                    billingAddress1: document.getElementById('address').value,
                    billingCity: document.getElementById('city').value,
                    billingPostcode: document.getElementById('postcode').value,
                    billingCountry: document.getElementById('country').value,
                    email: document.getElementById('email').value,
                }
            };
            payment.tokenize(
                data, //additional data, MUST include card_holder (or first_name & last_name), month and year
                async function (token, cardData) { //success callback function
                    let response = await axios.get(`https://www.umrlice-api.com/api/${UMRLICE_LARAVEL_API_TOKEN}/init-payment?token=${token}&user_id=${localStorage.getItem('userId')}&billingAddress1=${data.customer.billingAddress1}&billingCity=${data.customer.billingCity}&billingCountry=${data.customer.billingCountry}&billingPostcode=${data.customer.billingPostcode}`);
                    if (response?.data.success === true) {
                        //NotificationManager.success('Prebacujemo Vas na stranicu za plaćanje');
                        setTimeout(() => {
                            window.location.href = response.data.redirectUrl;
                        }, 1500);
                    } else {
                        document.getElementById('confirm_button').removeAttribute('disabled');
                        NotificationManager.error('Došlo je do greške. Molim Vas da kontaktirate korisničku podršku');
                    }
                },
                function (errors) { //error callback function
                    errors.map(e => {
                        document.getElementById('confirm_button').removeAttribute('disabled');
                        NotificationManager.error(e.message);
                    });
                }
            );
        } else {
            NotificationManager.error('Molimo ispunite sva polja');
        }
    }

    const clearCart = async () => {
        axios.get(`https://www.umrlice-api.com/api/${UMRLICE_LARAVEL_API_TOKEN}/cart/empty-fields?user_id=${localStorage.getItem('userId')}`).then(res => {
            setHasProduct(false);
        });

        const response = await fetch(
            `${API_URL}/api/posts/${product.id}/`,
            {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
            }
        );

        if (response.ok) {
            console.log("Post declined successfully");
        } else {
            // Handle unauthorized or error response
            console.log("Error declining post:", response.status);
        }
    }

    return (
        <Container
            className="my-5"
            style={{ paddingBottom: "6.5%", paddingTop: "100px" }}
        >
            {hasProduct && (<Row>
                <div className="container py-5">
                    <div className="row mb-4">
                        <div className="col-lg-8 mx-auto text-center">
                            <h1 className="display-6">Informacije o kartici</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 mx-auto">
                            <div className="card ">
                                <div className="card-header">
                                    <div className="tab-content">
                                        <div id="credit-card" className="tab-pane fade show active pt-3">
                                            <div className="form-group mb-3">
                                                <label htmlFor="card_holder">
                                                    <h6 className="text-black">
                                                        Vlasnik kartice
                                                    </h6>
                                                </label>
                                                <input type="text" name="card_holder" id="card_holder" placeholder="Ime na kartici"
                                                    required className="form-control" />
                                            </div>

                                            <div className="form-group mb-3">
                                                <label htmlFor="email">
                                                    <h6 className="text-black">
                                                        Email
                                                    </h6>
                                                </label>
                                                <input type="text" name="email" id="email" placeholder="Email"
                                                    required className="form-control" />
                                            </div>
                                            <div className="form-group mb-3">
                                                <label htmlFor="city">
                                                    <h6 className="text-black">
                                                        Grad
                                                    </h6>
                                                </label>
                                                <input type="text" name="city" id="city" maxLength="50" placeholder="Grad"
                                                    required className="form-control" />
                                            </div>
                                            <div className="form-group mb-3">
                                                <label htmlFor="country">
                                                    <h6 className="text-black">
                                                        Zemlja
                                                    </h6>
                                                </label>
                                                {/*<input type="text" name="country" id="country" placeholder="Zemlja"*/}
                                                {/*       required className="form-control" />*/}
                                                <select className="form-select" id="country" aria-label="Default select example">
                                                    <option value="" selected>Izaberi</option>
                                                    {countries.map(e => {
                                                        return <option value={e.code}>{e.name}</option>;
                                                    })}
                                                </select>
                                            </div>

                                            <div className="form-group mb-3">
                                                <label htmlFor="address">
                                                    <h6 className="text-black">
                                                        Adresa
                                                    </h6>
                                                </label>
                                                <input type="text" name="address" id="address" maxLength="50" placeholder="Adresa"
                                                    required className="form-control" />
                                            </div>

                                            <div className="form-group mb-3">
                                                <label htmlFor="postcode">
                                                    <h6 className="text-black">
                                                        Poštanski broj
                                                    </h6>
                                                </label>
                                                <input type="text" name="postcode" id="postcode" maxLength="16" placeholder="Poštanski broj"
                                                    required className="form-control" />
                                            </div>
                                            <div className="form-group mb-2">
                                                <label htmlFor="number_div">
                                                    <h6 className="text-black">Broj kartice</h6>
                                                </label>
                                                <div className="input-group">
                                                    {/*<input type="text" name="cardNumber"
                                               placeholder="Valid card number"
                                               className="form-control " required />*/}
                                                    <div id="number_div" style={{ height: "35px" }}></div>
                                                    <div className="input-group-append">
                                                        <img src={require("../assets/visa-cof-blue.png")} style={{ width: "60px", marginLeft: "10px" }} />
                                                        <img src={require("../assets/master.png")} style={{ width: "60px", marginLeft: "10px" }} />
                                                        <img src={require("../assets/maestro.png")} style={{ width: "60px", marginLeft: "10px" }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-8">
                                                    <div className="form-group">
                                                        <label>
                                                            <span className="hidden-xs">
                                                                <h6 className="text-black">Datum isteka</h6>
                                                            </span>
                                                        </label>
                                                        <div className="input-group">
                                                            <input type="number" placeholder="MM" min="1" max="100" id="exp_month"
                                                                className="form-control" required />
                                                            <input type="number" placeholder="YY" id="exp_year" min="1" max="100" className="form-control"
                                                                required />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group mb-4">
                                                        <label data-toggle="tooltip"
                                                            title="Three digit CV code on the back of your card">
                                                            <h6 className="text-black">CVV
                                                                <i className="fa fa-question-circle d-inline"></i>
                                                            </h6>
                                                        </label>
                                                        <div id="cvv_div" style={{ height: '35px' }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex mb-3">
                                                <input type="checkbox" name="terms" id="terms" style={{ marginRight: "10px", cursor: "pointer" }} />
                                                <a href="/terms" target="_blank">Slažem se sa uslovima plaćanja</a>
                                            </div>
                                            <div className="row justify-content-around">
                                                <div className="col-6">
                                                    <b id="total">Ukupno: </b>
                                                </div>
                                                <div className="col-6 float-end">
                                                    <button onClick={submitCreditCardInformation}
                                                        className="subscribe btn btn-primary btn-block shadow-sm float-end" id="confirm_button">Potvrdite
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    {/*<div className="row mb-4">
                        <div className="col-lg-6 mx-auto">
                            <h1 className="display-6">Product</h1>
                        </div>
                    </div>*/}
                    <div className="row justify-content-center">
                        <div className="p-5 pt-1" style={{ width: "500px", border: "3px solid blue" }}>
                            <div className="col-12 pt-0">
                                <p className="pr-0" style={{
                                    fontStyle: "italic",
                                    border: "none",
                                    display: "flex",
                                    justifyContent: "end",
                                    paddingRight: "2vh",
                                    backgroundColor: "transparent",
                                    color: "gray",
                                    fontSize: "smaller"
                                }}>Umrlica</p>
                            </div>
                            <div className="col-12 mt-3 text-center">
                                <img id="image" alt="POST IMAGE" style={{ width: "200px", height: "200px" }} />
                                <h1 id="name" className="mt-2" style={{ fontSize: "16px", fontWeight: "bold" }}></h1>
                                <p id="date"></p>
                            </div>
                            <div className="col-12 mb-3">
                                <p>
                                    <span id="main_text" style={{ fontWeight: "bold" }}></span>
                                </p>
                            </div>
                            <div className="col-12 align-content-lg-end">
                                <p>Ožalošćeni: <br />
                                    <span id="text_bottom" style={{ fontSize: "small", fontWeight: "bold" }}></span>
                                </p>
                            </div>

                            <div className="col-12 mt-5" style={{ textAlign: 'right', display: "inline-block" }}>
                                <b id="price"></b>
                            </div>
                            <div className="col-12 mt-5" style={{ textAlign: 'right', display: "inline-block" }}>
                                <button className="btn btn-danger" onClick={clearCart}>Remove</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Row>)}
            {!hasProduct && (
                <div className="container py-5">
                    <div className="row mb-4">
                        <div className="col-lg-8 mx-auto text-center">
                            <h1 className="display-6">Nemate proizvoda u korpi!</h1>
                        </div>
                    </div>
                </div>
            )}

            <NotificationContainer animation={false} />
        </Container>
    );
};

export default Payment;
