import React from "react";

const ForgotPasswordSuccess = () => {
  return (
    <div
      style={{
        textAlign: "center",
        position: "fixed",
        top: "40%",
        left: "auto",
        right: "auto",
        width: "100%",
      }}
    >
      <h2>Provjerite email kako biste resetovali lozinku</h2>
      <p style={{ textAlign: "center" }}>
        Ukoliko ne vidite mail u vašoj pošti (Inbox), molimo vas da provjerite i
        kategorije 'nepoželjne poruke (Spam)' kao i 'Social' i 'Promotion'
        kategorije ukoliko koristite Gmail.
      </p>
      <a href="/login">
        <button
          style={{ color: "white", background: "black", padding: "3px 8px" }}
        >
          Vrati se na login
        </button>
      </a>
    </div>
  );
};

export default ForgotPasswordSuccess;
