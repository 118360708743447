import React, { useState, useEffect, useRef, useCallback } from "react";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import { useParams } from "react-router-dom";
import axios from "axios";
import PoslednjiPozdrav from "./PoslednjiPozdrav";
import MediumPPozdrav from "./MediumPPozdrav";
import SmallPoslednjiPozdrav from "./SmallPoslednjiPozdrav";
import BigUmrlica from "./BigUmrlica";
import Umrlica from "./Umrlica";
import SmallUmrlica from "./SmallUmrlica";
import Pomen from "./Pomen";
import MediumPomen from "./MediumPomen";
import BigPomen from "./BigPomen";
import QuarterPP from "./QuarterPP";
import QuarterUmrlica from "./QuarterUmrlica";
import QuarterPomen from "./QuarterPomen";
import { API_URL } from "../config";

function SinglePost() {
  const myElementRef = useCallback((node) => {
    if (node === null) {
    } else {
      if (window.location.search === "?print=true") {
        setTimeout(() => {
          window.print();
        }, 1000);
      }
    }
  }, []);
  const [post, setPost] = useState(null);
  const { postId } = useParams();

  useEffect(() => {
    let isCanceled = false;

    const fetchPost = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/api/posts/${postId}`
        );

        if (!isCanceled) {
          setPost(response.data); // Update the state with the fetched post
        }
      } catch (error) {
        console.log("Error fetching post:", error);
      }
    };

    fetchPost();

    return () => {
      isCanceled = true;
    };
  }, [postId]);

  if (!post) {
    // Post is still being fetched, render a loading state or return null
    return null;
  }

  return (
    <Container style={{ paddingTop: "120px" }}>
      <Row style={{ justifyContent: "center" }}>
        {post.size === 3 && post.type === "poslednji_pozdrav" && (
          <Col ref={myElementRef} lg={12}>
            <PoslednjiPozdrav post={post} />
          </Col>
        )}
        {post.size === 2 && post.type === "poslednji_pozdrav" && (
          <Col ref={myElementRef} lg={6}>
            <MediumPPozdrav post={post} />
          </Col>
        )}
        {post.size === 1 && post.type === "poslednji_pozdrav" && (
          <Col ref={myElementRef} lg={6}>
            {" "}
            <SmallPoslednjiPozdrav post={post} />
          </Col>
        )}
        {post.size === 4 && post.type === "poslednji_pozdrav" && (
          <Col ref={myElementRef} lg={6}>
            {" "}
            <QuarterPP post={post} />
          </Col>
        )}
        {post.size === 1 && post.type === "umrlica" && (
          <Col ref={myElementRef} lg={6}>
            {" "}
            <SmallUmrlica post={post} />
          </Col>
        )}
        {post.size === 2 && post.type === "umrlica" && (
          <Col ref={myElementRef} lg={6}>
            {" "}
            <Umrlica post={post} />
          </Col>
        )}
        {post.size === 3 && post.type === "umrlica" && (
          <Col ref={myElementRef} lg={12}>
            {" "}
            <BigUmrlica post={post} />
          </Col>
        )}
        {post.size === 4 && post.type === "umrlica" && (
          <Col ref={myElementRef} lg={12}>
            {" "}
            <QuarterUmrlica post={post} />
          </Col>
        )}
        {post.size === 1 && post.type === "pomen" && (
          <Col ref={myElementRef} lg={6}>
            {" "}
            <Pomen post={post} />
          </Col>
        )}
        {post.size === 4 && post.type === "pomen" && (
          <Col ref={myElementRef} lg={6}>
            {" "}
            <QuarterPomen post={post} />
          </Col>
        )}
        {post.size === 2 && post.type === "pomen" && (
          <Col ref={myElementRef} lg={6}>
            {" "}
            <MediumPomen post={post} />
          </Col>
        )}
        {post.size === 3 && post.type === "pomen" && (
          <Col ref={myElementRef} lg={12}>
            {" "}
            <BigPomen post={post} />
          </Col>
        )}
      </Row>
    </Container>
  );
}

export default SinglePost;
