import React from "react";

const TraditionAndPractices = () => {
  return (
    <div className="container" style={{ paddingTop: "140px" }}>
      {" "}
      <h2 className="text-center">Tradicija i običaji</h2>
      <h4 className="mt-5">Pravoslavna vjeroispovijest</h4>
      <p className="mt-3" style={{ fontStyle: "italic" }}>
        Obavijest o smrti
      </p>
      <p className="mt-3">
        Kada smrt nastupi, članovi porodice preminulog prvo obavještavaju
        rodbinu i bliske prijatelje, a zatim se obavijest proširuje i na ostale
        poznanike. Uobičajeno je da bliski srodnici odmah dođu u dom pokojnika
        kako bi pružili podršku porodici preminulog. Oni koji su u mogućnosti,
        često odmah pružaju finansijsku podršku porodici, bilo kao zajam ili
        poklon, dok se ne obavi sahrana. Smrt dolazi neočekivano, stoga je
        podrška prijatelja i porodice važna. Svještenik se pravovremeno
        informiše o datumu, mjestu i vremenu sahrane. Pokojnik se oblači u
        pripremljenu odjeću, obično u najbolje što je imao i postavlja se u
        kovčeg. U znak žalosti, porodica pokojnika počinje da nosi crninu. Žene
        nose crni šal, a muškarci crnu košulju ili flor. Crnina se nosi
        četrdeset dana, šest mjeseci ili godinu dana, i tokom tog vremena
        porodica se suzdržava od sudjelovanja u zabavama i ne pjeva. Bitno je
        istaknuti da se, bez obzira na smrti u porodici, uvijek obilježava krsna
        slava kao i inače. Tokom godine dana nakon smrti, porodica se suzdržava
        od organizovanja zabava, vjenčanja ili sličnih događaja.
      </p>
      <p className="mt-3">
        U svim tim slučajevima, i u vezi sa svim običajima oko sahrane,
        preporučuje se savjetovanje sa svještenikom jer se običaji mogu
        razlikovati u različitim regijama.
      </p>{" "}
      <p className="mt-3" style={{ fontStyle: "italic" }}>
        Izjava saučešća
      </p>
      <p className="mt-3">
        Prije sahrane, rodbina, bliski prijatelji, kolege sa posla i oni koji su
        provodili puno vremena sa pokojnikom dolaze u dom kako bi izrazili
        saučešće porodici. Oni koji ne mogu doći, kao i oni iz šireg kruga
        prijatelja, dolaze na mjesto sahrane i tu, prije početka službe,
        izražavaju saučešće porodici. Porodica može primiti saučešće kod kuće
        ili u kapeli na groblju. Oni koji izražavaju saučešće poklanjaju se nad
        kovčegom, rukuju se prvo sa najbližim ženskim članovima porodice
        pokojnika koji stoje u kapeli kod glave pokojnika, a potom i sa
        najbližim muškim članovima porodice koji stoje ispred kapele,
        izjavljujući riječi saučešća.
      </p>{" "}
      <p className="mt-3" style={{ fontStyle: "italic" }}>
        Obred prilikom sahrane
      </p>
      <p className="mt-3">
        Za obred prilikom sahrane priprema se kuvana pšenica, boca vina i boca
        pomiješanog vina i ulja. Svještenik dolazi u dogovoreno vrijeme i drži
        opelo. Nakon opela, svještenik poziva na posljednji oproštaj. Nakon
        oproštaja, kovčeg se zatvara i formira se povorka koju predvodi krst,
        iza kojeg idu mladi noseći cvijeće, zatim kovčeg sa pokojnikom, iza
        kovčega svještenik pjeva posmrtne pjesme, a za svještenikom ide porodica
        i ostali prisutni.
      </p>
      <p className="mt-3" style={{ fontStyle: "italic" }}>
        Sahrana
      </p>
      <p className="mt-3">
        Sahrana se vrši na groblju. Na grobu se obavlja kratka molitva, ponekad
        prijatelj pokojnika izgovori nekoliko riječi u znak sjećanja na
        pokojnika, nakon čega se kovčeg spušta u grob. Prilikom toga, običaj je
        prekrstiti se, baciti šaku zemlje u grob uz riječi: ‚‚Neka mu je laka
        zemlja." U nekim područjima, osoba koja je u povorci nosila krst (na
        čelu povorke) prva odlazi sa groblja jer se vjeruje da smrt ostaje na
        groblju, a radost se vraća u dom porodice pokojnika.
      </p>
      <p className="mt-3" style={{ fontStyle: "italic" }}>
        Povratak kući
      </p>
      <p className="mt-3">
        Kod pravoslavaca je običaj da se nakon sahrane ide u kuću pokojnika. Taj
        običaj se održao i danas. Porodica poziva sve prisutne da ‚‚svrate"
        kući, a na poziv se odazivaju kumovi i najbliži prijatelji. Ostali
        dolaze prema svojim mogućnostima.
      </p>
      <p className="mt-3" style={{ fontStyle: "italic" }}>
        Običaji vezani za pomen
      </p>
      <p className="mt-3">
        Pomeni obuhvataju pojedinačne pomene mrtvih koji se vrše nakon smrti:
        četrdeseti dan, nakon pola godine i nakon godinu dana.
      </p>
      <h4 className="mt-5">Katolička vjeroispovijest</h4>
      <p className="mt-3">
        Za katolike, smrt se doživljava kao završni susret sa Bogom. Uprkos tuzi
        koju smrtni rastanak donosi, vjeruje se u nadu ponovnog susreta sa
        voljenima kod Boga.
      </p>
      <p className="mt-3">
        Tradicija je održavanje bdijenja uz pokojnikov odar, što se smatra činom
        pobožnosti i molitve za pokojnikovu dušu. Bdijenje se može održati u
        kapeli, crkvi ili u porodičnom domu, gdje rodbina i prijatelji donose
        cvijeće i pale svijeće u znak poštovanja.
      </p>
      <p className="mt-3" style={{ fontStyle: "italic" }}>
        Obred sahrane
      </p>
      <p className="mt-3">
        Sahrana u katoličkoj crkvi obuhvata obrede koji se sastoje od tri
        dijela. Prvi dio obavlja se kod pokojnika gdje svještenik sveti vodom
        pokojnika i moli za oprost grijeha. Drugi dio uključuje molitvu pred
        svima prisutnima i čitanje odlomka iz Evanđelja, nakon čega kreće
        povorka prema groblju. U trećem dijelu, nad grobom, svještenik
        blagoslivlja mjesto posljednjeg počinka vjerujući da je to mjesto sada
        pod Božjom zaštitom.
      </p>
      <p className="mt-3">
        Katolička crkva dopušta autopsiju i kremaciju pod određenim uslovima, a
        porodica izražava žalost kroz odijevanje i ponašanje, suzdržavajući se
        od zabave i veselja.
      </p>
      <p className="mt-3" style={{ fontStyle: "italic" }}>
        Običaji nakon sahrane
      </p>
      <p className="mt-3">
        Vjeruje se da molitve pomažu dušama pokojnika, a Sveta misa se smatra
        najvišim oblikom molitve. Svještenik služi svete mise zadušnice, koje
        članovi porodice često naručuju za svoje pokojne. Mise za pokojne služe
        se i osmi i trideseti dan nakon smrti, te kroz posebne molitve i mise
        tokom prvog mjeseca nakon smrti, podržavajući duše pokojnika na njihovom
        putu.
      </p>
      <h4 className="mt-5">Islamska vjeroispovijest</h4>
      <p className="mt-3">
        Za muslimane, smrt predstavlja realnost koja se odlikuje veličanstvenom
        mudrošću i jednostavnošću, a ne katastrofom. Smrt se vidi kao vrsta
        prelaska, razdvajanje duše od tijela i njeno usmjeravanje u ‚‚dom prvog
        nagrađivanja ili kažnjavanja.” Smrt ne označava kraj života, već
        promjenu stanja, svojevrsno novo rođenje.
      </p>
      <p className="mt-3" style={{ fontStyle: "italic" }}>
        Žal za preminulim
      </p>
      <p className="mt-3">
        U trenutku smrti muslimana, naricanje, kuknjava i preglasno plakanje
        nisu dopušteni. Žalost bi trebala da bude izražena samo kroz stanje srca
        i osjećaja, bez spoljašnjih znakova. Žalost, barem formalni dio, kod
        muslimana traje tri dana i tri noći, dok žalost udovice traje četiri
        mjeseca i deset dana. Preporučljivo je posjetiti porodicu preminulog i
        izraziti saučešće (sućut), usmeno ili pismeno, naglašavajući
        neizbježnost smrti, moleći za oprost grijeha preminulog i raj za njega,
        te strpljenje i blagostanje za njegovu porodicu i rodbinu.
      </p>
      <p className="mt-3" style={{ fontStyle: "italic" }}>
        Priprema i sahrana
      </p>
      <p className="mt-3">
        Obaveza kupanja preminule osobe i njeno stavljanje u ćefine spada među
        vjerske dužnosti pri sahrani. Pripreme za sahranu ne bi trebalo odgađati
        bez opravdanog razloga.
      </p>
      <p className="mt-3" style={{ fontStyle: "italic" }}>
        Dženaza-namaz i ispraćaj
      </p>
      <p className="mt-3">
        Nakon što je porodica obaviještena o smrti, a preminula osoba
        pripremljena prema vjerskim propisima, obavlja se dženaza-namaz.
      </p>
      <p className="mt-3" style={{ fontStyle: "italic" }}>
        Nošenje i ispraćaj
      </p>
      <p className="mt-3">
        Kovčeg se tradicionalno nosio na rukama do džamije i groblja, ali u
        gradovima se sada koriste prilagođena vozila. Tradicija nošenja kovčega
        podrazumijeva da pratioci nose kovčeg najmanje četrdeset koraka. Svi
        prisutni prate povorku sa kovčegom u tišini. Prisutni ne bi smjeli da
        sjede dok se kovčeg sa pokojnikom ne položi u zemlju.
      </p>
      <p className="mt-3" style={{ fontStyle: "italic" }}>
        Pokop
      </p>
      <p className="mt-3">
        Uobičajeno je da član porodice, ili neka pobožna osoba, spusti
        preminulog u kabur, okrenutog prema Kibli, dok se recitira određena
        molitva. Nakon pokopa, prisutni sudjeluju u zakopavanju, a nakon toga se
        čita Kur'an i moli za pokojnika.
      </p>
      <p className="mt-3" style={{ fontStyle: "italic" }}>
        Posjeta grobu
      </p>
      <p className="mt-3">
        Posjeta grobovima je stara praksa koju preporučuje islam kako bi se
        iskazalo poštovanje prema preminulima. Prilikom posjeta groblju,
        posjetioci treba da se odnose sa poštovanjem da izbjegavaju da gaze ili
        sjede na grobovima. Vjeruje se da su preminuli svjesni posjeta i da im
        to donosi radost.
      </p>{" "}
      <p className="mt-3" style={{ fontStyle: "italic" }}>
        Nakon pokopa
      </p>
      <p className="mt-3">
        Imam ili pobožna osoba može iskoristiti priliku nakon dženaze (sahrane)
        da uputi riječi prisutnima, umjesto tradicionalnog posmrtnog govora,
        podsjećajući na važne pouke i vrijednosti.
      </p>
    </div>
  );
};

export default TraditionAndPractices;
