import React from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  TelegramShareButton,
  ViberShareButton,
  FacebookMessengerShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  FacebookIcon,
  WhatsappIcon,
  EmailIcon,
  FacebookMessengerIcon,
  TelegramIcon,
  ViberIcon,
} from "react-share";
const ShareButtons = ({ id }) => {
  return (
    <div
      style={{
        float: "right",
        display: "flex",
        justifyContent: "end",
        paddingBottom: "2%",
        paddingRight: "1%",
      }}
    >
      <FacebookShareButton
        title="Facebook"
        url={`https://umrlice.vijesti.me/posts/${id}`}
      >
        <FacebookIcon
          bgStyle={{ fill: "black" }}
          iconFillColor="white"
          size={25}
          round
        />
      </FacebookShareButton>
      <span title="Viber">
        <ViberShareButton url={`https://umrlice.vijesti.me/posts/${id}`}>
          <ViberIcon
            bgStyle={{ fill: "black" }}
            iconFillColor="white"
            size={25}
            round
          />
        </ViberShareButton>
      </span>
      <EmailShareButton
        title="Email"
        url={`https://umrlice.vijesti.me/posts/${id}`}
        subject="Check out this post"
      >
        <EmailIcon
          bgStyle={{ fill: "black" }}
          iconFillColor="white"
          size={25}
          round
        />
      </EmailShareButton>
      <FacebookMessengerShareButton
        title="Messenger"
        url={`https://umrlice.vijesti.me/posts/${id}`}
      >
        <FacebookMessengerIcon
          bgStyle={{ fill: "black" }}
          iconFillColor="white"
          size={25}
          round
        />
      </FacebookMessengerShareButton>
      <span title="Telegram">
        <TelegramShareButton
          title="Telegram"
          url={`https://umrlice.vijesti.me/posts/${id}`}
        >
          <TelegramIcon
            bgStyle={{ fill: "black" }}
            iconFillColor="white"
            size={25}
            round
          />
        </TelegramShareButton>
      </span>
      <span title="What's App">
        <WhatsappShareButton
          url={`https://umrlice.vijesti.me/posts/${id}`}
          title="Check out this post"
        >
          <WhatsappIcon
            bgStyle={{ fill: "black" }}
            iconFillColor="white"
            size={25}
            round
          />
        </WhatsappShareButton>
      </span>
    </div>
  );
};

export default ShareButtons;
