import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the styles
function PreviewSmPP({ multiple }) {
  return (
    <Container fluid>
      <Row lg={12} md={12} sm={12}>
        <div
          style={{ height: "auto", marginTop: "20px" }}
          className="poslednji-pozdrav"
        >
          <p
            style={{
              textAlign: "center",
              fontWeight: "500",
              fontSize: "small",
              paddingBottom: "0",
              padding: "0",
            }}
          >
            Gornji tekst
          </p>
          <div className="title-container">
            <Col className="d-flex justify-content-around">
              {multiple && (
                <Carousel
                  showArrows={false}
                  infiniteLoop={true}
                  autoPlay={true}
                  interval={1000}
                >
                  <img
                    style={{
                      width: "150px",
                      height: "150px",
                      border: "3px solid black",
                    }}
                    src={require("../assets/user2.png")}
                    alt={`Image`}
                  />
                  <img
                    style={{
                      width: "150px",
                      height: "150px",
                      border: "3px solid black",
                    }}
                    src={require("../assets/user2.png")}
                    alt={`Image`}
                  />
                  <img
                    style={{
                      width: "150px",
                      height: "150px",
                      border: "3px solid black",
                    }}
                    src={require("../assets/user2.png")}
                    alt={`Image`}
                  />
                </Carousel>
              )}
              {!multiple && (
                <img
                  style={{
                    width: "150px",
                    height: "150px",
                    border: "3px solid black",
                  }}
                  src={require("../assets/user2.png")}
                  alt={`Image`}
                />
              )}
            </Col>
            <h4 style={{ fontSize: "20px" }}>Ime Prezime</h4>
          </div>
          <p
            style={{ fontSize: "15px", padding: "0" }}
            className="poslednji-pozdrav-big-text"
          >
            Glavni tekst poslednjeg pozdrava
          </p>
          <p
            style={{ paddingTop: "0" }}
            className="ozalosceni-p-poslednji-pozdrav"
          >
            Od koga
          </p>
        </div>
      </Row>
    </Container>
  );
}

export default PreviewSmPP;
