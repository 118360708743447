import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import MediumReklama from "./MediumReklama";
import Reklama from "./Reklama";
import TinyReklama from "./TinyReklama";

const CurrentAds = ({ ads }) => {
  return (
    <Container>
      <h3 style={{ textAlign: "center" }}>Aktivne reklame</h3>
      <Row>
        <Col lg={6} sm={12}>
          <Row>
            <span className="text-center mt-3">Srednja reklama</span>
            <MediumReklama animate={false} ads={ads} />
          </Row>
        </Col>
        <Col lg={6} sm={12}>
          <Row>
            <span className="text-center mt-3">Mala reklama</span>
            <Reklama animate={false} ads={ads} />
          </Row>
          <Row className="mt-3">
            <span className="text-center">Najmanja reklama</span>
            <TinyReklama animate={false} ads={ads} />
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default CurrentAds;
