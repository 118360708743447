import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

function VerifiedByVisaPage() {
    return (
        <Container>
            <Row style={{marginTop: "20rem"}}>
                <p>Same card, additional online security.</p>
                <p>Verified by Visa is a new service that provides online purchase with the additional security.</p>

                <p>
                    By simple Verified by Visa process, your identity is confirmed when you make an online purchase.
                    Your identity is confirmed when you make an online purchase in the shops
                    included in the program. This convenient method of purchase is possible with your existing
                    Visa card.
                </p>
                <p>
                    Besides, Verified by Visa is a fast process. It is just required to register your card once
                    and create a password. Then, after making online purchase in the shops included in the program,
                    Verified by Visa window will be displayed. Simply enter your password and click “submit".
                    Your identity is confirmed and purchase is secure.
                </p>
                <p>
                    In order to activate Verified by Visa in your Visa card and learn more, contact the financial institution that has issued your Visa card.
                    For more information on Verified by Visa program, click on the <a href="https://www.visa.co.uk/about-visa/visa-in-europe.html" target="_blank">www.visaeu.com</a>.
                </p>
            </Row>
        </Container>
    );
}

export default VerifiedByVisaPage;
