import React from "react";

const ProbateProceedings = () => {
  return (
    <div className="container" style={{ paddingTop: "140px" }}>
      <h2 className="text-center">Ostavinski postupak</h2>
      <p className="mt-3" style={{ fontStyle: "italic" }}>
        Postupak pokretanja
      </p>
      <p className="mt-3">
        Postupak se pokreće nakon smrti ostavitelja sljedećim koracima:
      </p>
      <ol className="mt-5">
        <li>
          Obratite se Odjeljenju za upravne poslove u Ministarstvu unutrašnjih
          poslova (MUP), gdje će vas pitati o detaljima vezanim za pokojnika,
          rodbinu, postojanje testamenta i procjenu vrijednosti ostavljene
          imovine.
        </li>
        <li>
          Nadležna služba MUP-a izrađuje smrtovnicu koja se predaje Osnovnom
          sudu zajedno sa prijedlogom za otvaranje postupka ostavinske rasprave.
          U prijedlogu se navode naslednici, njihove tačne adrese i sastav
          nasledstva, kao i bilo koji drugi podaci potrebni sudu za postupanje.
        </li>
        <li>
          Sud zakazuje ročište i poziva sve zainteresovane strane na adrese
          navedene u smrtovnici.
        </li>
      </ol>
      <p className="mt-3">Prilikom dolaska na ročište u sud potrebno je:</p>
      <ul className="mt-5">
        <li>Donijeti ličnu kartu ili pasoš;</li>
        <li>Ako postoji, donijeti testament pokojnika;</li>
        <li>Donijeti dokaze o imovini ostavitelja:</li>
        <li>
          Za nekretnine: priložiti izvode iz katastra, ne starije od šest
          mjeseci;
        </li>
        <li>
          Za pokretnine: npr. saobraćajnu dozvolu za vozila, štednu knjižicu za
          ušteđevinu u banci, sertifikate itd.
        </li>
      </ul>
      <p className="mt-3">Tokom postupka</p>
      <p className="mt-3">
        Osnovni sud utvrđuje pravo na nasledstvo i donosi odluku o nasleđivanju.
        Na kraju postupka, sud određuje sudsku taksu koju naslednici moraju
        platiti. Visina takse određuje se prema vrijednosti imovine ostavitelja,
        a naslednici je plaćaju proporcionalno svojem dijelu nasledstva.
      </p>
      <p className="mt-3">
        Za sve dodatne informacije, obratite se službenicima u pisarnici suda.
        Više informacija možete pronaći na web stranici:{" "}
        <a href="http://sudovi.me">http://sudovi.me</a>
      </p>
      <h4>Životno osiguranje</h4>
      <p className="mt-3">
        Kada dođe do smrti osiguranika, iznos osiguranja dogovoren na polici
        životnog osiguranja isplaćuje se korisnicima polise (naslednicima). U
        slučaju da je polisa obuhvatala osiguranje za smrt, naslednicima se
        isplaćuje dogovoreni iznos osiguranja uz dodatak pripadajuće dobiti.
      </p>
      <p className="mt-3">
        Ako korisnik osiguranja u slučaju smrti nije specificiran na polisi
        životnog osiguranja, potrebno je dostaviti pravno rješenje o
        nasleđivanju, na osnovu kojeg se naslednicima isplaćuje osigurana suma.
      </p>
      <p className="mt-3">
        U situaciji kada je uzrok smrti bolest, zahtijeva se predaja dodatne
        dokumentacije (medicinski karton osiguranika) radi provjere istinitosti
        odgovora osiguranika na medicinska pitanja postavljena prilikom
        sklapanja ugovora o osiguranju, koja su dio ponude. Ako se utvrdi da
        osiguranik nije dao tačne informacije u odgovorima, isplata osigurane
        sume može biti odbijena (u slučaju da je neotkrivena bolest pri
        sklapanju ugovora uticala na nastanak smrti) ili smanjena (ako prethodno
        poznata bolest nije imala uticaj na smrtni ishod) za postotak kojim bi
        premija bila uvećana da je osiguravajuća kuća bila upoznata sa pravim
        zdravstvenim stanjem osiguranika.
      </p>
    </div>
  );
};

export default ProbateProceedings;
