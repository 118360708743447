export const getFormSizeName = (s) => {
  let name = "";
  const FormSize = {
    Large: 3,
    Medium: 2,
    Small: 1,
    Tiny: 4,
  };
  if (s === FormSize.Large) {
    name = "Velika";
  } else if (s === FormSize.Medium) {
    name = "Srednja";
  } else if (s === FormSize.Small) {
    name = "Mala";
  } else if (s === FormSize.Tiny) {
    name = "Najmanja";
  }

  return name;
};
