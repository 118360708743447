import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import { API_URL } from "../config";

import ShareButtons from "./ShareButtons";
import { isSerbianCyrillic } from "../submitFormUtils/isSerbianCyrlic";
function QuarterUmrlica({ post, modalIsOpen }) {
  const [counter, setCounter] = useState(post.counter);
  const [imageSrc, setImageSrc] = useState(require("../assets/candle.png"));
  const deletePosts = async () => {
    const accessToken = localStorage.getItem("accessToken");
    try {
      const response = await axios.delete(`${API_URL}/api/posts/${post.id}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      window.location.href = "/";
    } catch (error) {
      console.log("Error fetching posts:", error);
    }
  };

  const handleCandleClick = () => {
    // Toggle between two image sources
    if (imageSrc === require("../assets/candle.png")) {
      setImageSrc(require("../assets/candleColor.png"));
    } else {
      setImageSrc(require("../assets/candle.png"));
    }
    increaseCounter();
  };

  const convertDate = (date) => {
    const [year, month, day] = date.split("-");

    if (day == undefined) {
      return year;
    }

    return [day, month, year].join("/");
  };
  const fullDate = (birth_date, death_date) => {
    if (birth_date && death_date) {
      return `${convertDate(birth_date)} - ${convertDate(death_date)}`;
    }

    return null;
  };

  const increaseCounter = async () => {
    try {
      setCounter(post.counter + 1);
      await axios.post(`${API_URL}/api/posts/${post.id}/counter/up/`, {});
    } catch (error) {
      console.log("Error fetching posts:", error);
    }
  };
  const userRole = localStorage.getItem("userRole");
  return (
    <Container
      data-aos="fade-up"
      fluid
      style={{
        fontSize: "small",
        minHeight: "150px",
        border: `5px groove ${
          post && post.color === "blue"
            ? "blue" // Use the specific color for "blue"
            : post.color === "green" ||
              post.color === "black" ||
              post.color === "red"
            ? post.color // Use other colors for "green," "black," or "red"
            : "black" // Default color for other cases
        }`,
      }}
      className="post"
    >
      {" "}
      <Row
        style={{
          fontStyle: "italic",
          border: "none",
          borderTop: "none",
          display: "flex",
          justifyContent: "end",
          paddingRight: "0.5rem",
          backgroundColor: "transparent",
          color: "gray",
          fontSize: "smaller",
        }}
      >
        {isSerbianCyrillic(post.name) ? "умрлица" : post.type}
      </Row>
      <Row lg={12} md={12} sm={12}>
        <div style={{ height: "auto" }}>
          <div
            className="title-container"
            style={{ padding: "0px", paddingTop: "0" }}
          >
            <h5 style={{ fontSize: "20px", padding: "0" }}>{post.name}</h5>
            <span>{fullDate(post.birth_date, post.death_date)}</span>
          </div>

          {!modalIsOpen && (
            <div className="predaj-pp-button">
              {isSerbianCyrillic(post.name)
                ? "Предај последњи поздрав"
                : "Predaj poslednji pozdrav"}{" "}
            </div>
          )}

          {modalIsOpen && <p style={{ width: "100%" }}>{post.text_bottom}</p>}
          {modalIsOpen &&
            (userRole === "admin" || userRole === "superadmin") && (
              <Col>
                <button
                  style={{ marginLeft: "3%", marginBottom: "2%" }}
                  className="btn btn-danger"
                  onClick={() => deletePosts()}
                >
                  Obriši
                </button>
              </Col>
            )}
        </div>
      </Row>
      <Row style={{ padding: "2%", paddingLeft: "0" }}>
        <hr style={{ margin: "0", marginBottom: "1%" }}></hr>
        <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
          {isSerbianCyrillic(post.name) ? "Ожалошћени" : "Ožalošćeni"}
          ...
        </span>
        {modalIsOpen && <p style={{ paddingTop: "1%" }}>{post.text_bottom}</p>}
      </Row>{" "}
      {modalIsOpen && <ShareButtons id={post.id} />}
    </Container>
  );
}

export default QuarterUmrlica;
