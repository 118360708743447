import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

function MasterCardSecureCodePage() {
    return (
        <Container>
            <Row style={{marginTop: "20rem"}}>
                <p>
                    MasterCard®SecureCode™ is a new service of MasterCard and a Bank issuer of your card <br />
                    that provides you with additional security when you make an online purchase.
                </p>
                <p>
                    For this service, you need a new MasterCard or Maestro® card.  You chose your own <br />
                    MasterCardSecureCode and do not share it with any online Merchant ever. The private code <br />
                    means the additional security against unauthorized use of your debit or credit card when <br />
                    you make an online purchase.
                </p>
                <p>
                    When you make a payment with MasterCard or Maestro card, a window of your Bank <br />
                    issuer of the card will be opened and ask to enter your personal SecureCode, same as you <br />
                    are required to enter PIN in the ATM. In just few seconds the issuer of your card confirms <br />
                    your data and thus enables you to complete your online shopping.
                </p>

                <p>
                    For more information on MasterCard SecureCodeprogram, click on the <br />
                    <a href="http://www.mastercardsecurecode.com" target="_blank">www.mastercardsecurecode.com</a>.
                </p>
            </Row>
        </Container>
    );
}

export default MasterCardSecureCodePage;
