import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Offcanvas } from "react-bootstrap";

function Header({ searchQuery, handleSearchChange, isLoggedIn }) {
  const location = useLocation();
  const [showTopOffcanvas, setShowTopOffcanvas] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const isMobile = window.innerWidth >= 992;
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        setShowTopOffcanvas(false);
      }
    };

    // Add event listener when the component mounts
    document.body.addEventListener("keypress", handleKeyPress);

    // Clean up the event listener when the component unmounts
    return () => {
      document.body.removeEventListener("keypress", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    if (window.location.pathname === "/") {
      setShowSearch(true);
    } else if (window.location.pathname === "/umrlice") {
      setShowSearch(true);
    } else if (window.location.pathname === "/poslednji-pozdravi") {
      setShowSearch(true);
    } else if (window.location.pathname === "/pomeni") {
      setShowSearch(true);
    } else {
      setShowSearch(false);
    }
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      const shouldBeSticky = window.scrollY > 0;

      setIsSticky(shouldBeSticky);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location.pathname]);

  return (
    <header
      style={{ background: `${isSticky ? "#f2f2f2" : ""}` }}
      className={`header sticky`}
    >
      <Container style={{ padding: "0" }}>
        <div
          className="top-header"
          style={{
            display: "flex",
            paddingTop: "10px",
            marginBottom: "5px",
            width: "100%",
            fontSize: isMobile ? "small" : "normal",
          }}
        >
          <a
            style={{
              textDecoration: "none",
              color: "black",
              display: "flex",
              alignItems: "center",
              paddingRight: "10px",
            }}
            href="/contact-information"
          >
            Kontakt informacije
          </a>{" "}
          <span style={{ paddingLeft: "3px", paddingRight: "3px" }}>|</span>
          <a
            style={{
              textDecoration: "none",
              color: "black",
              display: "flex",
              alignItems: "center",
              paddingLeft: "5px",
              paddingRight: "5px",
            }}
            href="/pricing"
          >
            Cjenovnik
          </a>
          {isMobile && (
            <>
              <span style={{ paddingLeft: "3px", paddingRight: "3px" }}>|</span>
              <a
                style={{
                  textDecoration: "none",
                  color: "black",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
                href="/postupci-u-slucaju-smrti"
              >
                Postupci u slučaju smrti
              </a>
              <span style={{ paddingLeft: "3px", paddingRight: "3px" }}>|</span>
              <a
                style={{
                  textDecoration: "none",
                  color: "black",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
                href="/tradicija-i-obicaji"
              >
                Tradicija i običaji
              </a>
              <span style={{ paddingLeft: "3px", paddingRight: "3px" }}>|</span>
              <a
                style={{
                  textDecoration: "none",
                  color: "black",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
                href="/pravo-zakona-o-fondu-pio"
              >
                Pravo Zakona o penzijskom i invalidskom osiguranju
              </a>
              <span style={{ paddingLeft: "3px", paddingRight: "3px" }}>|</span>
              <a
                style={{
                  textDecoration: "none",
                  color: "black",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
                href="/ostavinski-postupak"
              >
                Ostavinski postupak
              </a>
              <span style={{ paddingLeft: "3px", paddingRight: "3px" }}>|</span>
              <a
                style={{
                  textDecoration: "none",
                  color: "black",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
                href="/pogrebne-usluge"
              >
                Pogrebne usluge
              </a>{" "}
            </>
          )}
        </div>
        <Offcanvas
          style={{ height: "80px" }}
          show={showTopOffcanvas}
          onHide={() => setShowTopOffcanvas(false)}
          placement="top"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Body>
              {" "}
              <input
                style={{
                  border: "1px solid white",
                  outline: "none",
                  width: "100%",
                  color: "black",
                }}
                type="text"
                name="search"
                className="offCanvas-search"
                placeholder="Pretraži po imenu..."
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </Offcanvas.Body>
          </Offcanvas.Header>
        </Offcanvas>
        <Offcanvas
          show={showOffcanvas}
          onHide={() => setShowOffcanvas(false)}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Meni</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav.Link
              href="/"
              className={location.pathname === "/" ? "active-page" : ""}
            >
              Početna
            </Nav.Link>
            <Nav.Link
              href="/umrlice"
              className={location.pathname === "/umrlice" ? "active-page" : ""}
            >
              Umrlice
            </Nav.Link>
            <Nav.Link
              href="/poslednji-pozdravi"
              className={
                location.pathname === "/poslednji-pozdravi" ? "active-page" : ""
              }
            >
              Posljednji pozdravi
            </Nav.Link>
            <Nav.Link
              href="/pomeni"
              className={location.pathname === "/pomeni" ? "active-page" : ""}
            >
              Pomeni
            </Nav.Link>
            <Nav.Link
              href="/predaj-citulju"
              className={
                location.pathname === "/predaj-citulju" ? "active-page" : ""
              }
            >
              Predaj čitulju
            </Nav.Link>
            {!isLoggedIn && (
              <Nav.Link
                href="/login"
                className={location.pathname === "/login" ? "active-page" : ""}
              >
                Uloguj se
              </Nav.Link>
            )}
            {!isLoggedIn && (
              <Nav.Link
                href="/register"
                className={
                  location.pathname === "/register" ? "active-page" : ""
                }
              >
                Registruj se
              </Nav.Link>
            )}
            {isLoggedIn && (
              <Nav.Link
                href="/payment"
                className={
                  location.pathname === "/payment" ? "active-page" : ""
                }
              >
                Korpa
              </Nav.Link>
            )}
            <hr></hr>
            <div
              style={{
                width: "100%",
                justifyContent: "cENTER",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <a
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  color: "black",
                  padding: "5px",
                }}
                href="/about-us"
              >
                O nama
              </a>
              <a
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  color: "black",
                  padding: "5px",
                }}
                href="/postupci-u-slucaju-smrti"
              >
                Postupci u slučaju smrti
              </a>
              <a
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  color: "black",
                  padding: "5px",
                }}
                href="/tradicija-i-obicaji"
              >
                Tradicija i običaji
              </a>
              <a
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  color: "black",
                  padding: "5px",
                }}
                href="/pravo-zakona-o-fondu-pio"
              >
                Pravo Zakona o penzijskom i invalidskom osiguranju
              </a>
              <a
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  color: "black",
                  padding: "5px",
                }}
                href="/ostavinski-postupak"
              >
                Ostavinski postupak
              </a>
              <a
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  color: "black",
                  padding: "5px",
                }}
                href="/pogrebne-usluge"
              >
                Pogrebne usluge
              </a>
            </div>
            <input
              style={{
                border: "none",
              }}
              type="text"
              className="search-click-lg"
              name="search"
              placeholder="Pretraži po imenu..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
            {isLoggedIn && (
              <Nav.Link
                href="/user-profile"
                className={
                  location.pathname === "/user-profile" ? "active-page" : ""
                }
              >
                Moj profil
              </Nav.Link>
            )}
          </Offcanvas.Body>
        </Offcanvas>

        <Navbar
          bg="white"
          expand="lg"
          className="d-flex align-items-center nav-menu"
        >
          <img
            onClick={() => setShowTopOffcanvas(!showTopOffcanvas)}
            height={30}
            className="small-nav-img"
            src={require("../assets/search.png")}
            alt="search-icon"
            style={{ marginLeft: "20px", display: "none" }}
          />{" "}
          <a href="/">
            <img
              className="logo"
              height={50}
              src={require("../assets/citulje-logo.png")}
              alt="logo"
            />
          </a>
          <Navbar.Toggle onClick={() => setShowOffcanvas(!showOffcanvas)} />
          <Nav style={{ fontWeight: "bold" }} className="ms-auto lg-nav">
            <Nav.Link
              href="/"
              className={location.pathname === "/" ? "active-page" : ""}
            >
              Početna
            </Nav.Link>
            <Nav.Link
              href="/umrlice"
              className={location.pathname === "/umrlice" ? "active-page" : ""}
            >
              Umrlice
            </Nav.Link>
            <Nav.Link
              href="/poslednji-pozdravi"
              className={
                location.pathname === "/poslednji-pozdravi" ? "active-page" : ""
              }
            >
              Posljednji pozdravi
            </Nav.Link>
            <Nav.Link
              href="/pomeni"
              className={location.pathname === "/pomeni" ? "active-page" : ""}
            >
              Pomeni
            </Nav.Link>
            <Nav.Link
              href="/predaj-citulju"
              className={
                location.pathname === "/predaj-citulju" ? "active-page" : ""
              }
            >
              Predaj čitulju
            </Nav.Link>

            {isLoggedIn && (
              <Nav.Link
                href="/payment"
                className={
                  location.pathname === "/payment" ? "active-page" : ""
                }
              >
                Korpa
              </Nav.Link>
            )}

            {showSearch && (
              <input
                style={{
                  border: "none",
                }}
                type="text"
                className="search-click-lg"
                name="search"
                placeholder="Pretraži po imenu..."
                value={searchQuery}
                onChange={handleSearchChange}
              />
            )}

            <Nav.Link
              href={isLoggedIn ? "/user-profile" : "/login"}
              className={
                location.pathname === "/user-profile" ? "active-page" : ""
              }
            >
              <img
                className="profile-icon"
                alt="profile-icon"
                height={20}
                src={require("../assets/user-updated.png")}
              />
            </Nav.Link>
          </Nav>
        </Navbar>
      </Container>
    </header>
  );
}

export default Header;
