import React from "react";

const PaymentTerms = () => {
  return (
    <div className="container" style={{ paddingTop: "140px" }}>
      <h2 className="text-center">Uslovi korišćenja</h2>
      <h4 className="text-center mt-3"> Izjava o konverzijama</h4>
      <p>
        Sva plaćanja će biti obavljena u eurima (€). Ukoliko se plaćanje obavlja platnim karticama inostranih Banaka izdavalaca, iznos transakcije će biti konvertovan u lokalnu valutu korisnika kartice, prema kursu karticnih kuća Visa/Mastercard.
      </p>
      <p>
        Please note that all payments will be effected in Euro (EUR). If the
        payment is done using foreign issuers payment cards, total amount of
        transaction will be converted into bank settlement currency, according
        to the current exchange rate of Visa/Mastercard.
      </p>
      <h4 className="text-center mt-3">Povraćaj sredstava</h4>
      <p>
        U slučaju povraćaja sredstava kupcu koji je predhodno platio nekom od platnih kartica, delimično ili u cjelosti, a bez obzira na razlog vraćanja, taj povraćaj se vrši isključivo preko iste VISA, Maestro ili MasterCard kartice koja je upotrijebljena za plaćanje. Ovo znači da će naša banka na naš zahtjev obaviti povraćaj sredstava na račun korisnika kartice.
      </p>
      <h4 className="text-center mt-3">Zaštita podataka kartice</h4>
      <p>
        Prilikom unošenja podataka o platnoj kartici, povjerljive informacije se prenose putem javne mreže u zaštićenoj (kriptovanoj) formi upotrebom SSL protokola, primjenom najsavremenijih metoda tokenizacije osjetljivih podataka, i u skladu sa PCI-DSS standardima. Niti jednog trenutka podaci o platnoj kartici nisu dostupni trgovcu. 3D Secure zaštita za sve trgovce i kupce - AllSecure Payment Gateway koristi najviše globalne standarde zaštite i privatnosti podataka. Svi trgovci koji koriste AllSecure Payment Gateway su automatski uključeni u 3D-Secure zaštitu, čime se kupcima garantuje bezbjednost kupovine. Brojevi platnih kartica kupaca se ne čuvaju na sistemu trgovca a sami upis se štiti SSL enkripcijom podataka. PCI DSS Standardi - AllSecure Payment Gateway se konstantno usaglašava sa svim zahtjevima kartičarskih organizacija u cilju povećanja nivoa bezbjednosti trgovaca i kupaca. Od 2005 godine do danas, bez prekida, sistem je sertifikovan kao PCI-DSS Level 1 što predstavlja najviši standard u industriji. PCI Data Security Standard (PCI-DSS) je norma koja definiše neophodne mjere bezbjednosti pri obradi, čuvanju i prenošenju osjetljivih kartičarskih podataka. PCI Standardima se štite osjetljivi podaci o korisniku kartice tokom cijlog procesa plaćanja: od momenta unosa podataka na prodajnom mjestu trgovca, tokom komunikacija između trgovca i relevantnih banaka i kartičarskih organizacija, kao i kasnije čuvanje tih podataka.
      </p>
      <h4 className="text-center mt-3">Načini plaćanja</h4>
      <p>
        Plaćanje proizvoda na našoj internet prodavnici je moguće izvršiti na jedan od sljedećih načina: platnim karticama - VISA, Maestro ili MasterCard koje podržavaju plaćanje preko Interneta. Plaćanje karticama je realizovano u saradnji sa AllSecure doo i Hipotekarna Banka i obavlja se na bezbijedan i sertifikovan način preko AllSecure Paymet Gateway-a, jednostavnim unosom podataka sa platne kartice. Nakon što se unesu podaci o kartici i potvrdi plaćanje, banka autorizuje transakciju i time je porudžbina odobrena i ulazi u dalji proces pripreme za isporuku. Iznos će biti rezervisan na vašoj kartici (računu) i neće Vam biti raspoloživ za drugu namjenu. Transkacija će biti kompletirana i iznos skinut sa vašeg računa tek kada su proizvodi pripremljeni za transport i predati kurirskoj službi. U slučaju da se plaćanje ne kompletira, odnosno iznos se ne skine sa računa do isteka 14 dana od prihvatanja Vaše porudžbine, ta porudžbenica će biti otkazana i izbrisana. Nakon isteka roka od 14 dana, novac rezervisan na Vašem računu se oslobađa i biće Vam ponovo na raspolaganju. Nakon toga možete ponoviti istu ili novu porudžbinu, i izvršiti plaćanje vezano za njih. Provjerite kod banke koja je karticu izdala da li Vaša kartica podržava plaćanje preko Interneta.
      </p>
      <h4 className="text-center">Upiti i žalbe</h4>
      <p className="mt-3">
        Usluge koje se prodaju putem naše internet prodavnice, praćeni su svom zakonski neophodnom dokumentacijom. Ukoliko se pojavi potreba da reklamirate određenu objavu, mi ćemo ispuniti sve zakonski definisane obaveze za prodavca.
      </p>
      <p className="mt-3">
        Ukoliko želite da započnete proces reklamacije, ili imate dodatna pitanja ili žalbe, potrebno je da nam se obratite na e-mail info.umrlice@vijesti.me ili telefon +382 20 404 604 i +382 67 247 242
      </p>
      <p className="mt-3">
        Preduzeće Daily Press d.o.o ne preuzima nikakvu odgovornost za greške kupaca ili bilo koje druge greške koje se odnose na Vaše postupke prilikom objave čitulja na web sajtu.
      </p>
      <p className="mt-3">
        Ukoliko imate poteškoće u radu prilikom objava na web sajtu, molimo Vas da kontaktirate sa našim korisničkim servisom na broj +382 20 404 604 i mi ćemo se potruditi da otklonimo eventualne nepravilnosti.
      </p>
    </div>
  );
};

export default PaymentTerms;
