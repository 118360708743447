export const handleIncrementDay = (birthDate, setBirthDate) => {
  const newDate = new Date(birthDate);
  newDate.setDate(birthDate.getDate() + 1);
  setBirthDate(newDate);
};

export const handleDecrementDay = (birthDate, setBirthDate) => {
  const newDate = new Date(birthDate);
  newDate.setDate(birthDate.getDate() - 1);
  setBirthDate(newDate);
};

export const handleIncrementDeathDay = (deathDate, setDeathDate) => {
  const newDate = new Date(deathDate);
  newDate.setDate(deathDate.getDate() + 1);
  setDeathDate(newDate);
};

export const handleDecrementDeathDay = (deathDate, setDeathDate) => {
  const newDate = new Date(deathDate);
  newDate.setDate(deathDate.getDate() - 1);
  setDeathDate(newDate);
};
