export function extractDate(input) {
  // Ensure that input is a string
  const inputString = String(input);

  // Regular expression to match date patterns
  const datePattern = /\w{3} (\w{3} \d{2} \d{4})/g;

  // Extract date matches from the input
  const dateMatches = inputString.match(datePattern);

  if (dateMatches === null) {
    return [];
  }

  // Initialize an array to store the extracted dates
  const extractedDates = dateMatches.map((dateMatch) => {
    // Parse the date match into a Date object
    const date = new Date(dateMatch);

    // Extract day, month, and year components
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are 0-indexed, so add 1
    const year = date.getFullYear();

    // Format the date in the desired format (day/month/year)
    return `${day}/${month}/${year}`;
  });

  return extractedDates;
}
