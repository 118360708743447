// Umrlica.js
import React from "react";
import { Container, Col, Row } from "react-bootstrap";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the styles
function PreviewSmUmrlica({ multiple }) {
  const modalStyle = {
    border: "8px groove black",
    backgroundColor: "white",
    height: "315px",
    marginTop: "20px",
  };
  return (
    <Container style={modalStyle}>
      <Row></Row>
      <Row style={{ paddingTop: "10%" }}>
        <Col className="d-flex justify-content-around"></Col>
        <Col className="d-flex justify-content-around">
          {multiple && (
            <Carousel
              showArrows={false}
              infiniteLoop={true}
              autoPlay={true}
              interval={1000}
            >
              <img
                style={{
                  width: "150px",
                  height: "150px",
                  border: "3px solid black",
                }}
                src={require("../assets/user2.png")}
                alt={`Image`}
              />
              <img
                style={{
                  width: "150px",
                  height: "150px",
                  border: "3px solid black",
                }}
                src={require("../assets/user2.png")}
                alt={`Image`}
              />
              <img
                style={{
                  width: "150px",
                  height: "150px",
                  border: "3px solid black",
                }}
                src={require("../assets/user2.png")}
                alt={`Image`}
              />
            </Carousel>
          )}
          {!multiple && (
            <img
              style={{
                width: "150px",
                height: "150px",
                border: "3px solid black",
              }}
              src={require("../assets/user2.png")}
              alt={`Image`}
            />
          )}
        </Col>
        <Col className="d-flex justify-content-around"></Col>
      </Row>
      <Row style={{ paddingTop: "2%", paddingBottom: "3%" }}>
        <Col className="d-flex justify-content-around"></Col>
        <Col
          style={{
            fontWeight: "bold",
            letterSpacing: "1.2px",
          }}
          className="d-flex justify-content-around"
        >
          Ime Prezime
        </Col>
        <Col className="d-flex justify-content-around"></Col>
      </Row>
      <Row
        style={{
          textAlign: "center",
          paddingBottom: "5%",
          paddingLeft: "2%",
          paddingRight: "2%",
        }}
      >
        <span style={{ textAlign: "center" }}>datum - datum</span>
      </Row>
    </Container>
  );
}

export default PreviewSmUmrlica;
