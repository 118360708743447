import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the styles

// Add the following line if you want to apply default styles to the modal

function PreviewPPozdrav({ multiple }) {
  return (
    <div>
      <Container fluid>
        <Row lg={12} md={12} sm={12}>
          <div style={{ marginTop: "20px" }} className="poslednji-pozdrav">
            <p>Gornji tekst</p>
            <div className="title-container">
              <Col className="d-flex justify-content-around">
                {multiple && (
                  <Carousel
                    showArrows={false}
                    infiniteLoop={true}
                    autoPlay={true}
                    interval={1000}
                  >
                    <img
                      style={{
                        width: "150px",
                        height: "150px",
                        border: "3px solid black",
                      }}
                      src={require("../assets/user2.png")}
                      alt={`Image`}
                    />
                    <img
                      style={{
                        width: "150px",
                        height: "150px",
                        border: "3px solid black",
                      }}
                      src={require("../assets/user2.png")}
                      alt={`Image`}
                    />
                    <img
                      style={{
                        width: "150px",
                        height: "150px",
                        border: "3px solid black",
                      }}
                      src={require("../assets/user2.png")}
                      alt={`Image`}
                    />
                  </Carousel>
                )}
                {!multiple && (
                  <img
                    style={{
                      width: "150px",
                      height: "150px",
                      border: "3px solid black",
                    }}
                    src={require("../assets/user2.png")}
                    alt={`Image`}
                  />
                )}
              </Col>
              <h4>Ime Prezime</h4>
              <h6 style={{ fontSize: "20px" }}>datum - datum</h6>
            </div>
            <p className="poslednji-pozdrav-big-text-lg">
              Glavni tekst poslednjeg pozdrava
            </p>
            <p
              style={{ paddingTop: "0" }}
              className="ozalosceni-p-poslednji-pozdrav"
            >
              Od koga
            </p>
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default PreviewPPozdrav;
