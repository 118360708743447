import React from "react";
import { Form, Button, Row } from "react-bootstrap";
import { Formik } from "formik";
import axios from "axios";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { API_URL } from "../config";

const CreateUser = () => {
  const handleSubmit = async (values, formikBag) => {
    try {
      // Create a FormData object to hold form data
      const formData = new FormData();

      // Append form field values to the FormData object
      formData.append("first_name", values.first_name);
      formData.append("last_name", values.last_name);
      formData.append("email", values.email);
      formData.append("password", values.password);

      // Set headers for the POST request
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      };

      // Send the POST request using Axios
      const response = await axios.post(
        `${API_URL}/api/regular/add/`,
        formData,
        { headers } // Pass the headers to the request
      );

      // If the request is successful, display a success notification
      NotificationManager.success(
        "Uspješno ste se kreirali nalog regularnog korisnika"
      );

      // Reset the Formik form
      formikBag.resetForm();
    } catch (error) {
      // If there's an error, log it to the console
      console.error("Error:", error);
    }
  };

  return (
    <Formik
      initialValues={{
        first_name: "",
        last_name: "",
        email: "",
        password: "",
      }}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, setFieldValue, values, handleChange }) => (
        <Form
          className="FormaZaPopunjavanje-form create-user"
          onSubmit={handleSubmit}
          style={{
            margin: "0 auto",
            marginTop: "3%",
            maxWidth: "500px",
            padding: "20px",
            backgroundColor: "#f5f5f5",
            borderRadius: "5px",
          }}
        >
          <div>
            <p>Tip: Regularni korisnik</p>
            <Row>
              <Form.Group controlId="formUserFirstName">
                <h6>Ime</h6>
                <Form.Control
                  required
                  placeholder="Unesite ime..."
                  name="first_name"
                  onChange={handleChange}
                  value={values.first_name}
                  className="FormaZaPopunjavanje-input"
                  style={{ marginTop: "8px" }}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group controlId="formUserLastName">
                <h6>Prezime</h6>
                <Form.Control
                  required
                  placeholder="Unesite prezime..."
                  name="last_name"
                  onChange={handleChange}
                  value={values.last_name}
                  className="FormaZaPopunjavanje-input"
                  style={{ marginTop: "8px" }}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group controlId="formCreateUserEmail">
                <h6>Email</h6>
                <Form.Control
                  required
                  placeholder="Unesite e-mail adresu..."
                  name="email"
                  onChange={handleChange}
                  value={values.email}
                  className="FormaZaPopunjavanje-input"
                  style={{ marginTop: "8px" }}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group controlId="formUserPassword">
                <h6>Lozinka</h6>
                <Form.Control
                  required
                  placeholder="Unesite lozinku..."
                  type="password"
                  name="password"
                  onChange={handleChange}
                  value={values.password}
                  className="FormaZaPopunjavanje-input"
                  style={{ marginTop: "8px" }}
                />
              </Form.Group>
            </Row>
            <Button
              className="FormaZaPopunjavanje-submit"
              variant="primary"
              type="submit"
              style={{ marginTop: "16px" }}
            >
              Potvrdi
            </Button>
          </div>
          <NotificationContainer />
        </Form>
      )}
    </Formik>
  );
};

export default CreateUser;
