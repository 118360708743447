// Umrlica.js
import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";

function PreviewQrUmrlica() {
  const modalStyle = {
    border: "8px groove black",
    backgroundColor: "white",
    height: "155px",
    marginTop: "20px",
  };
  return (
    <Container style={modalStyle}>
      <Row></Row>
      <Row style={{ paddingTop: "5%" }}>
        <Col className="d-flex justify-content-around"></Col>

        <Col className="d-flex justify-content-around"></Col>
      </Row>
      <Row style={{ paddingTop: "2%", paddingBottom: "1%" }}>
        <Col className="d-flex justify-content-around"></Col>
        <Col
          style={{
            fontWeight: "bold",
            letterSpacing: "1.2px",
            textAlign: "center",
            fontSize: "larger",
          }}
          className="d-flex justify-content-around"
        >
          Ime Prezime
        </Col>
        <Col className="d-flex justify-content-around"></Col>
      </Row>
      <Row
        style={{
          textAlign: "center",
          paddingBottom: "0%",
          paddingLeft: "2%",
          paddingRight: "2%",
        }}
      >
        <span style={{ textAlign: "center" }}>datum - datum</span>
        <Row
          style={{
            textAlign: "center",
            paddingBottom: "1%",
          }}
        >
          <p style={{ textAlign: "center" }}>Primjer kratke recenice</p>
        </Row>
      </Row>
    </Container>
  );
}

export default PreviewQrUmrlica;
