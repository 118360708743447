import React, { useState } from "react";
import { Col, Table } from "react-bootstrap";
import axios from "axios";
import { API_URL } from "../config";

function Admins({ adminUsers, fetchUsers }) {
  const [searchValue, setSearchValue] = useState("");

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const deleteUser = (adminId) => {
    const accessToken = localStorage.getItem("accessToken");

    axios
      .delete(
        `${API_URL}/api/admin/${adminId}/delete/`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          // Remove the deleted admin from the admins array
          fetchUsers();
        } else {
          console.error("Failed to delete user:", response.status);
        }
      })
      .catch((error) => {
        console.error("Error deleting user:", error);
      });
  };

  const filteredAdmins = adminUsers.filter(
    (user) =>
      user.first_name.toLowerCase().includes(searchValue.toLowerCase()) ||
      user.last_name.toLowerCase().includes(searchValue.toLowerCase())
  );
  return (
    <Col style={{ paddingTop: "20px" }} lg={6}>
      <div style={{ maxHeight: "300px", overflow: "auto" }}>
        <input
          style={{
            borderRadius: "8px",
            backgroundColor: "black",
            color: "white",
            marginBottom: "10px",
          }}
          type="text"
          value={searchValue}
          onChange={handleSearchChange}
          placeholder="Pretraži admine..."
        />

        <Table striped bordered hover variant="dark">
          <thead>
            <tr>
              <th>#</th>
              <th>Admini</th>
            </tr>
          </thead>
          <tbody>
            {filteredAdmins.map((admin, index) => (
              <tr key={admin.id}>
                <td>{index + 1}</td>
                <td>
                  {" "}
                  {admin.first_name} {admin.last_name}
                  {admin.type === "admin" && (
                    <button
                      style={{
                        backgroundColor: "white",
                        opacity: "0.5",
                        padding: "2px",
                        border: "1px solid white",
                        float: "right",
                        marginRight: "10px",
                        borderRadius: "8px",
                      }}
                      onClick={() => deleteUser(admin.id)}
                    >
                      <img
                        src={require("../assets/user-x-solid-24.png")}
                        alt="Delete"
                      />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Col>
  );
}

export default Admins;
