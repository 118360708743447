import React, { useEffect, useState } from "react";
import { API_URL } from "../config";

function AuthPage() {
  const [verificationStatus, setVerificationStatus] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");

    if (token) {
      const verificationUrl = `${API_URL}/api/activate/?token=${token}`;

      fetch(verificationUrl)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            setVerificationStatus("verified");
            window.location.href = "/";
          } else {
            setVerificationStatus("error");
          }
        })
        .catch((error) => {
          console.error("Verification failed", error);
          setVerificationStatus("error");
        });
    } else {
      setVerificationStatus("invalid");
    }
  }, []);

  return (
    <div>
      {verificationStatus === "verified" ? (
        <h1>Vas nalog je verifikovan</h1>
      ) : verificationStatus === "error" ? (
        <h1>Greska prilikom verifikacije</h1>
      ) : verificationStatus === "invalid" ? (
        <h1>Neispravan token u URL-u</h1>
      ) : (
        <h1>Verifikacija u toku...</h1>
      )}
    </div>
  );
}

export default AuthPage;
