import React, { useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import axios from "axios";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the styles
import { shortenString } from "../utils";
import ShareButtons from "./ShareButtons";
import { API_URL } from "../config";
import { isSerbianCyrillic } from "../submitFormUtils/isSerbianCyrlic";
const convertDate = (date) => {
  const splitData = date.split("-");

  if (splitData.length == 1) {
    return splitData[0];
  } else {
    const [year, month, day] = splitData;
    return [day, month, year].join("/");
  }
};
const fullDate = (birth_date, death_date) => {
  if (birth_date && death_date) {
    return `${convertDate(birth_date)} - ${convertDate(death_date)}`;
  }

  return null;
};

function Umrlica({ post, modalIsOpen }) {
  const [imageSrc, setImageSrc] = useState(require("../assets/candle.png"));
  const [counter, setCounter] = useState(post.counter);
  const userRole = localStorage.getItem("userRole");
  const isSmallScreen = window.innerWidth <= 1024;
  const handleCandleClick = () => {
    // Toggle between two image sources
    if (imageSrc === require("../assets/candle.png")) {
      setImageSrc(require("../assets/candleColor.png"));
    } else {
      setImageSrc(require("../assets/candle.png"));
    }
    increaseCounter();
  };

  const deletePosts = async () => {
    const accessToken = localStorage.getItem("accessToken");
    try {
      const response = await axios.delete(`${API_URL}/api/posts/${post.id}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      window.location.href = "/";
    } catch (error) {
      console.log("Error fetching posts:", error);
    }
  };

  const increaseCounter = async () => {
    try {
      await axios.post(`${API_URL}/api/posts/${post.id}/counter/up/`, {});

      setCounter(counter + 1);
    } catch (error) {
      console.log("Error fetching posts:", error);
    }
  };

  const modalStyle = {
    border: `5px solid ${
      post && post.color === "blue"
        ? "blue" // Use the specific color for "blue"
        : post.color === "green" ||
          post.color === "black" ||
          post.color === "red"
        ? post.color // Use other colors for "green," "black," or "red"
        : "black" // Default color for other cases
    }`,
    backgroundColor: "white",
    minHeight: "655px",
    padding: "0",
    border: `5px groove ${
      post && post.color !== null && post.color !== undefined
        ? post.color
        : "black"
    }`,
    width: "100%",
  };
  const mainAndBottomTextLength =
    (post.main_text?.length || 0) + (post.text_bottom?.length || 0);

  // If the combined text length is less than or equal to 350, increase font size and padding
  const shouldIncreaseSize = mainAndBottomTextLength <= 350;
  return (
    <Container
      data-aos="fade-up"
      className="post"
      id={modalIsOpen ? "umrlica-md-open" : "umrlica-md"}
      style={modalIsOpen ? { ...modalStyle, height: "auto" } : modalStyle}
      lg={6}
      sm={12}
      md={12}
    >
      <span
        className="post-type"
        style={{
          fontStyle: "italic",
          border: "none",
          borderTop: "none",
          textAlign: "right",
          backgroundColor: "white",
          color: "gray",
          fontSize: "smaller",
          float: "right",
          marginRight: "1rem",
        }}
      >
        {isSerbianCyrillic(post.name) ? "умрлица" : post.type}
      </span>
      <Col
        style={{ marginTop: "30px" }}
        className="d-flex justify-content-center"
      >
        {post.icon !== "Bez ikonice" && (
          <img
            id="umrlica-icon"
            style={{
              height: "60px",
              width: "60px",
              border: "none",
            }}
            src={post.icon}
            alt=""
          />
        )}
      </Col>{" "}
      <Row style={{ paddingTop: "3%", fontSize: "medium" }}>
        <p style={{ textAlign: "center" }}>{post.text_top}</p>
      </Row>
      <Row style={{ paddingTop: "2%", margin: "0" }}>
        <Col className="d-flex justify-content-around">
          {post.images.length !== 0 && (
            <Carousel
              showThumbs={false}
              showArrows={false}
              infiniteLoop={true}
              autoPlay={true}
              interval={2500}
            >
              {post.images
                .reduce((p, c) => p + c)
                .split(",")
                .map((imagePath, index) => (
                  <div className="d-flex justify-content-center" key={index}>
                    <div
                      style={{
                        width: "120px",
                        height: "150px",
                        border: "3px solid black",
                        background: `url(${API_URL}/${imagePath})`,
                        backgroundSize: "cover",
                        backgroundPosition: "50% 50%",
                      }}
                    ></div>
                  </div>
                ))}
            </Carousel>
          )}

          {post.images.length === 0 && (
            <div
              style={{
                width: "150px",
                height: "150px",
              }}
            ></div>
          )}
        </Col>
      </Row>
      <Row style={{ paddingTop: "3%", margin: "0" }}>
        <Col
          style={{
            fontWeight: "bold",
            letterSpacing: "1.2px",
            textAlign: "center",
          }}
          className="d-flex justify-content-around"
        >
          {post.name.includes("\n")
            ? post.name
                .split("\n")
                .slice(0, 2)
                .map((line, index, array) => (
                  <React.Fragment key={index}>
                    {shortenString(line, 150)}
                    {index < array.length - 1 && <br />}
                  </React.Fragment>
                ))
            : post.name}
        </Col>
      </Row>
      <Col className="d-flex justify-content-around">
        <span style={{ fontSize: "small", paddingTop: "1%" }}>
          {fullDate(post.birth_date, post.death_date)}
        </span>
      </Col>
      {!modalIsOpen && (
        <Row style={{ margin: "0" }}>
          <div
            className="main-text"
            style={{
              paddingLeft: shouldIncreaseSize ? "5%" : "2%",
              paddingRight: shouldIncreaseSize ? "5%" : "2%",
              paddingTop: "2%",
              textAlign: "left",
              lineHeight: "1",
            }}
          >
            <span
              className="main-text"
              style={{
                textAlign: "left",
                fontSize: shouldIncreaseSize ? "16px" : "12px",
                lineHeight: shouldIncreaseSize ? "" : "1",
              }}
            >
              {post.main_text.includes("\n")
                ? post.main_text
                    .split("\n")
                    .slice(0, 3)
                    .map((line, index, array) => (
                      <React.Fragment key={index}>
                        {shortenString(line, 150)}
                        {index < array.length - 1 && <br />}
                      </React.Fragment>
                    ))
                : isSmallScreen
                ? shortenString(post.main_text, 150)
                : post.main_text}
              {post.main_text.split("\n").length > 2 && "..."}
            </span>
          </div>
        </Row>
      )}
      {modalIsOpen && (
        <Row style={{ margin: "0" }}>
          <div
            className="main-text"
            style={{
              paddingLeft: "5%",
              paddingRight: "5%",
              paddingTop: "2%",
              textAlign: "left",
            }}
          >
            {post.main_text.split("\n").map((s, index) => (
              <p key={index} className="main-text-line">
                {s.trim()}
              </p>
            ))}
          </div>
        </Row>
      )}
      {shouldIncreaseSize && <hr></hr>}
      {!modalIsOpen && (
        <Row style={{ margin: "0" }}>
          <h5
            className="bottom-text"
            style={{
              paddingLeft: shouldIncreaseSize ? "5%" : "2%",
              marginTop: "2%",
              fontSize: "small",
            }}
            id="sakrij"
          >
            {isSerbianCyrillic(post.name) ? "Ожалошћени" : "Ožalošćeni"}:
          </h5>
          <span
            className="bottom-text"
            style={{
              paddingLeft: shouldIncreaseSize ? "5%" : "2%",
              paddingBottom: "2%",
              fontSize: shouldIncreaseSize ? "15px" : "12px",
              lineHeight: shouldIncreaseSize ? "" : "1.2",
            }}
            id="sakrij"
          >
            <p>
              {isSmallScreen
                ? shortenString(post.text_bottom, 45)
                : post.text_bottom}
            </p>
          </span>
        </Row>
      )}
      {modalIsOpen && (
        <Row style={{ margin: "0" }}>
          <h5
            className="bottom-text"
            style={{ paddingLeft: "5%", fontSize: "small" }}
            id="sakrij"
          >
            {isSerbianCyrillic(post.name) ? "Ожалошћени" : "Ožalošćeni"}:
          </h5>
          <span
            className="bottom-text"
            style={{
              paddingLeft: "5%",
              paddingBottom: "2%",
            }}
            id="sakrij"
          >
            <div>
              {post.text_bottom.split("\n").map((s, index) => (
                <p className="bottom-text-line">{s.trim()}</p>
              ))}
            </div>
          </span>
        </Row>
      )}
      {!modalIsOpen && (
        <div className="predaj-pp-button">
          {isSerbianCyrillic(post.name)
            ? "Предај последњи поздрав"
            : "Predaj poslednji pozdrav"}{" "}
        </div>
      )}
      {modalIsOpen && (userRole === "admin" || userRole === "superadmin") && (
        <Col>
          <button
            style={{ marginLeft: "3%" }}
            className="btn btn-danger"
            onClick={() => deletePosts()}
          >
            Obriši
          </button>
        </Col>
      )}
      {modalIsOpen && <ShareButtons id={post.id} />}
    </Container>
  );
}

export default Umrlica;
