import React from "react";

const InquiriesAndAppeals = () => {
  return (
    <div className="container" style={{ paddingTop: "140px" }}>
      <h2 className="text-center">Upiti i žalbe</h2>
      <p className="mt-3">
        Usluge koje se prodaju putem naše internet prodavnice, praćeni su svom
        zakonski neophodnom dokumentacijom. Ukoliko se pojavi potreba da
        reklamirate određenu objavu, mi ćemo ispuniti sve zakonski definisane
        obaveze za prodavca.
      </p>
      <p className="mt-3">
        Ukoliko želite da započnete proces reklamacije, ili imate dodatna
        pitanja ili žalbe, potrebno je da nam se obratite na email
        info.umrlice@vijesti.me ili telefon +382 20 404 604 i +382 67 247 242
      </p>
      <p className="mt-3">
        Preduzeće Daily Press d.o.o ne preuzima nikakvu odgovornost za greške
        kupaca ili bilo koje druge greške koje se odnose na Vaše postupke
        prilikom objave čitulja na Web sajtu. Ukoliko imate nepravilnosti u radu
        prilikom objava na Web sajtu, molimo Vas da kontaktirate korisnički
        servis (+382 20 404 604) i mi ćemo se potruditi da otklonimo bilo kakve
        nepravilnosti nepravilnosti.
      </p>
    </div>
  );
};

export default InquiriesAndAppeals;
