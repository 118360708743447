import React from "react";

const LawOnFund = () => {
  return (
    <div className="container" style={{ paddingTop: "140px" }}>
      <h2 className="text-center">
        Pravo Zakona o penzijskom i invalidskom osiguranju
      </h2>
      <h4 className="mt-3" style={{ fontStyle: "italic" }}>
        Pravo na porodičnu penziju
      </h4>
      <p className="mt-3">
        Prema članu 42 Zakona o penzijskom i invalidskom osiguranju (PIO), pravo
        na porodičnu penziju ostvaruju članovi porodice:
      </p>
      <ol className="mt-3">
        <li>
          umrlog osiguranika koji je navršio najmanje pet godina staža
          osiguranja ili ispunio uslove za starosnu ili invalidsku penziju;
        </li>
        <li>umrlog korisnika starosne ili invalidske penzije.</li>
      </ol>
      <p className="mt-3">
        Članovima porodice umrlog osiguranika, odnosno korisnika prava iz člana
        42 ovog zakona smatraju se:
      </p>
      <ol className="mt-3">
        <li>bračni drug;</li>
        <li>
          djeca (rođena u braku ili van braka ili usvojena i pastorčad koje je
          osiguranik, odnosno korisnik prava izdržavao)
        </li>
      </ol>
      <p className="mt-3">
        Zakon o PIO takođe navodi da prava u slučaju invalidnosti i tjelesnog
        oštećenja prouzrokovanih povredom na radu ili profesionalnom bolešću
        ostvaruju lica ostvaruju lica:
      </p>
      <ol className="mt-3">
        <li>
          koja se nalaze na stručnom osposobljavanju, dokvalifikaciji ili
          prekvalifikaciji, koja uputi Zavod za zapošljavanje Crne Gore
        </li>
        <li>
          učenici i studenti kada se, u skladu sa zakonom, nalaze na obaveznom
          proizvodnom radu, profesionalnoj praksi ili praktičnoj nastavi;
        </li>
        <li>
          lica koja se nalaze na izdržavanju kazne zatvora, dok rade u ustanovi
          za izdržavanje kazne zatvora (radionica, radilište i sl.).
        </li>
      </ol>
      <p className="mt-5">Pravo na porodičnu penziju ostvaruju:</p>
      <ol className="mt-3">
        <li>Bračni drug;</li>
        <li>Djeca, uključujući rođenu, vanbračnu, usvojenu i pastorčad.</li>
        <li>Razvedeni bračni drug pod uslovima propisanim zakonom</li>
        <li>
          Udovci/udovice ostvaruju pravo na porodičnu penziju pod uslovima
          propisanim zakonom
        </li>
        <li>
          Pod uslovima koji se primjenjuju za bračnog druga pravo na porodičnu
          penziju može ostvariti životni partner umrlog osiguranika, pod
          uslovima propisanim zakonom
        </li>
      </ol>
      <p className="mt-3">
        Djeca imaju pravo na porodičnu penziju do 19. godine, ili do završetka
        školovanja, najkasnije do 24. godine ako pohađaju fakultet, a iznimno do
        25. godine za fakultetske programe duže od četiri godine. Ovo pravo se
        može koristiti samo do završetka osnovnih studija. Postdiplomske studije
        nisu obuhvaćene. Dijete sa posebnim potrebama ostvaruje pravo na
        porodičnu penziju nakon prestanka zaposlenja ili samostalne djelatnosti.
      </p>
      <p className="mt-3">
        Iznos porodične penzije određuje se kao postotak penzije koja bi
        pripadala osiguraniku u trenutku smrti, ili penzije koja je korisniku
        pripadala u trenutku smrti, zavisno o broju članova porodice koji imaju
        pravo na tu penziju.
      </p>
      <p className="mt-3">
        Kada je izdržavanje uslov za sticanje prava na porodičnu penziju, smatra
        se da je umrli osiguranik izdržavao člana porodice ako prosječni
        mjesečni prihodi člana porodice nisu premašivali 25% prosječne mjesečne
        zarade u Crnoj Gori.
      </p>
      <p className="mt-3">
        Školovanje je uslov za korišćenje prava na porodičnu penziju, ali se ne
        smatra školovanje u školi istog ili nižeg ranga od prethodno završenog.
      </p>
      <p className="mt-3">
        Porodična penzija isplaćuje se kao jedna penzija svim korisnicima, osim
        ako korisnici ne zahtijevaju odvojenu isplatu.
      </p>
      <p className="mt-3">
        Pravo na porodičnu penziju prestaje ako član porodice više ne ispunjava
        uslove za sticanje ili korišćenje tog prava.
      </p>
      <p className="mt-3">
        Ako se porodična penzija isplaćivala dva ili više članova porodice, a
        nekom od njih prestane pravo, penzija se ponovo određuje za preostale
        članove.
      </p>
      <p className="mt-6">
        Pravo na porodičnu penziju ne postoji ako je član porodice pravnosnažnom
        presudom osuđen za krivično djelo koje je rezultiralo smrću osobe od
        koje se pravo na porodičnu penziju izvodi. Pravo na penziju prestaje
        danom pravosnažnosti presude.
      </p>
      <p className="mt-3">
        Djeci bez oba roditelja, osim porodične penzije po jednom roditelju,
        pripada i dodatna porodična penzija po drugom roditelju, prema određenom
        postotku zavisno od broja djece, a ukupni iznos ne može premašiti iznos
        najviše starosne penzije za penzijski staž od 40 godina.
      </p>
      <p className="mt-6">
        Kada je izdržavanje uslov za sticanje prava na porodičnu penziju, smatra
        se da je umrli osiguranik izdržavao člana porodice ako njegovi prosječni
        mjesečni prihodi u godini prije smrti nisu prelazili 25% prosječne
        mjesečne zarade u Crnoj Gori. Prihodi koji se ne uzimaju u obzir
        prilikom ovog obračuna uključuju socijalna davanja, novčane naknade za
        pomoć i njegu, naknade za tjelesno oštećenje, nagrade, otpremnine zbog
        odlaska u penziju, te primanja po osnovu učeničkog i studentskog
        standarda.
      </p>
      <p className="mt-3">
        Ako je iznos penzije preminulog člana veći od iznosa penzije udovca ili
        udovice, porodična penzija može se prilagoditi da odražava viši mjesečni
        iznos, pod uslovom da se predaju odgovarajući dokumenti u područno
        odjeljenje Fonda PIO. Ukoliko se uslovi za ostvarivanje starosne penzije
        ispune kasnije, udovac ili udovica imaju pravo da odaberu koju penziju
        će primati, uzimajući u obzir da porodična penzija iznosi 70% od ukupne
        penzije.
      </p>
      <p className="mt-3">
        Ako se porodična penzija isplaćivala dva ili više članova porodice, a
        nekom od njih prestane pravo, penzija se ponovo određuje za preostale
        članove.
      </p>
      <p className="mt-3">
        Pravo na porodičnu penziju prestaje članu porodice koji zbog promjene
        okolnosti više ne ispunjava uslove za stjecanje ili korištenje tog
        prava.
      </p>
    </div>
  );
};

export default LawOnFund;
