import React from "react";

const AboutUs = () => {
  return (
    <div className="container" style={{ paddingTop: "140px" }}>
      <h2 className="text-center">O nama</h2>

      <p className="mt-3">
        Dobro došli na platformu Umrlice (
        <a href="umrlice.vijesti.me">umrlice.vijesti.me</a>), mjesto gdje počast
        i sjećanje postaju trajni. Naša misija je da Vam pružimo podršku u
        teškim trenucima gubitka, dajući Vam resurse i usluge koje olakšavaju
        proces komemoracije Vaših voljenih.
      </p>
      <p className="mt-3">
        Naš tim razumije važnost svakog detalja u ovom, za Vas, emotivnom
        procesu. Tu smo da pružimo podršku kako biste mogli odati počast životu
        vaše voljene osobe na način koji odražava njihovu jedinstvenu priču.
      </p>
      <p className="mt-3">
        Svaki aspekt naše platforme, od intuitivnog dizajna do pažljivo odabrane
        ponude objava o smrti i sjećanja, oblikovan je sa posebnom pažnjom kako
        bismo Vam olakšali organizaciju i izražavanje vaših osjećaja.
      </p>
      <p className="mt-3">
        Nadamo se da ćete pronaći inspiraciju i utjehu u našim uslugama i
        resursima. Hvala vam što ste nam ukazali Vaše povjerenje.
      </p>
      <p className="mt-3">
        Kontaktirajte sa nama na:
        <br></br>
        +382 20 404 604
        <br></br>
        +382 67 247 242
      </p>
    </div>
  );
};

export default AboutUs;
