import React, {useEffect, useState} from "react";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import {Container, Row} from "react-bootstrap";
import axios from "axios";
import {UMRLICE_LARAVEL_API_TOKEN} from "../../config";

const Payment = () => {
    const [transactionDetails, setTransactionDetails] = useState(null);

    useEffect( () => {
        axios.get(`https://www.umrlice-api.com/api/${UMRLICE_LARAVEL_API_TOKEN}/payment/last?user_id=${localStorage.getItem('userId')}`)
            .then(res => {
                if (res.data.success === false) {
                    window.location.href = '/predaj-citulju';
                } else {
                    setTransactionDetails(res.data);
                }

                    /*axios.get(`https://www.umrlice-api.com/api/${UMRLICE_LARAVEL_API_TOKEN}/cart/empty-fields?user_id=${localStorage.getItem('userId')}`).then(res => {
                        //window.location.href = '/predaj-citulju';

                        setTimeout(() => {
                            NotificationManager.error(
                                `Payment error`
                            );
                        }, 1000);
                    });*/
            });

    }, []);
    return (
        <Container
            className="my-5"
            style={{ paddingBottom: "6.5%", paddingTop: "100px"}}
        >
            <Row className="justify-content-center text-center">
                {transactionDetails && (
                    <div>
                        <span>Status: <b>{transactionDetails.transactionStatus}</b></span>
                        <br />
                        <span>Message: <b>{transactionDetails.errors[0]['message']}</b></span>
                    </div>
                )}
            </Row>

            <NotificationContainer animation={false} />
        </Container>
    );
};
export default Payment;
