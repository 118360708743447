import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";

import { FiEye, FiEyeOff } from "react-icons/fi";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import axios from "axios";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { API_URL } from "../config";

const apiUrl =
  `${API_URL}/api/reset_password/`;

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    if (password !== "superadmin") {
      if (password === "") {
        setErrorMessage(
          "Unesite lozinku od minimum 8 karaktera i jednim velikim slovom."
        );
      } else if (password.length < 8) {
        setErrorMessage("Lozinka mora biti duga minimum 8 karaktera!");
      } else if (!/[A-Z]/.test(password)) {
        setErrorMessage("Lozinka mora da sadrži minimum jedno veliko slovo!");
      } else if (!/[0-9]/.test(password)) {
        setErrorMessage("Lozinka mora da sadrži minimum jedan broj!");
      } else if (password !== confirmPassword) {
        setErrorMessage("Lozinke nisu iste.");
      } else {
        setErrorMessage(""); // Clear the error if the password is valid
      }
    } else {
      setErrorMessage(""); // Clear the error for the superadmin password
    }
  }, [password, confirmPassword]);

  const handleSubmit = async (values) => {
    values.password = password;
    if (errorMessage === "") {
      try {
        // Token should be obtained from the link, replace with your logic to get it
        const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get("token");

        const response = await axios.post(apiUrl, {
          token: token,
          new_password: values.password, // Send the new password
        });

        if (response.status === 200) {
          NotificationManager.success("Lozinka uspješno izmijenjena!");
          setTimeout(() => {
            // Redirect logic here
            window.location.href = "/login";
          }, 1000);
        } else {
          console.log("Password reset failed");
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setErrorMessage("Zahtjev za izmjenu lozinke neuspješan");
        } else {
          setErrorMessage(
            "Izmjena lozinke neuspješna. Molimo pokušajte ponovo."
          );
        }
      }
    }
  };

  return (
    <div className="reset-password-container">
      <Container
        className="my-5"
        style={{ paddingBottom: "6.5%", paddingTop: "5%" }}
      >
        <Formik
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
          onSubmit={handleSubmit}
        >
          {({ values }) => (
            <Row className="justify-content-center">
              <Col xs={12} md={8} lg={6}>
                <h1 className="text-center mb-4">Resetuj lozinku</h1>

                <Form
                  onKeyDown={(e) => {
                    if (e.key == "Enter") {
                      handleSubmit(values);
                    }
                  }}
                >
                  <div className="password-input">
                    <label htmlFor="password" className="form-label">
                      Nova lozinka
                    </label>
                    <div style={{ position: "relative" }}>
                      <Field
                        type={"password"}
                        name="password"
                        id="password"
                        className="form-control"
                        placeholder="Nova lozinka..."
                        value={password}
                        onChange={(e) => {
                          const newPassword = e.target.value;
                          setPassword(newPassword);
                        }}
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="confirmPassword" className="form-label">
                      Potvrdi novu lozinku
                    </label>
                    <div style={{ position: "relative" }}>
                      <Field
                        type={showPassword ? "text" : "password"}
                        name="confirmPassword"
                        id="confirmPassword"
                        className="form-control"
                        placeholder="Potvrdi novu lozinku..."
                        value={confirmPassword}
                        onChange={(e) => {
                          const newConfirmPassword = e.target.value;
                          setConfirmPassword(newConfirmPassword);
                        }}
                      />
                      <span
                        onClick={() => setShowPassword(!showPassword)}
                        className="password-toggle"
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                      >
                        {showPassword ? <FiEyeOff /> : <FiEye />}
                      </span>
                    </div>
                  </div>
                  {errorMessage && (
                    <p
                      style={{
                        color: "red",
                        margin: "0",
                        paddingBottom: "10px",
                      }}
                    >
                      {errorMessage}
                    </p>
                  )}
                  <Row>
                    <Col lg={5}>
                      <Button
                        style={{
                          color: "white",
                          backgroundColor: "black",
                          border: "none",
                          marginTop: "10px",
                        }}
                        className="reset-password-btn"
                        type="submit"
                        variant="primary"
                        onClick={handleSubmit}
                      >
                        Potvrdi
                      </Button>
                    </Col>
                    <Col style={{ padding: "15px" }} lg={4}>
                      <a
                        style={{
                          textDecoration: "underline",
                          color: "black",
                          fontSize: "15px",
                        }}
                        href="/login"
                      >
                        Nazad na login
                      </a>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          )}
        </Formik>
        <NotificationContainer />
      </Container>
    </div>
  );
};

export default ResetPassword;
