import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import axios from "axios";
import { Carousel } from "react-responsive-carousel";

import { shortenString } from "../utils";
import ShareButtons from "./ShareButtons";

import { API_URL } from "../config";
import { isSerbianCyrillic } from "../submitFormUtils/isSerbianCyrlic";

function SmallPoslednjiPozdrav({ post, type, modalIsOpen }) {
  const [imageSrc, setImageSrc] = useState(require("../assets/candle.png"));
  const [counter, setCounter] = useState(post.counter);
  const userRole = localStorage.getItem("userRole");
  const handleCandleClick = () => {
    // Toggle between two image sources
    if (imageSrc === require("../assets/candle.png")) {
      setImageSrc(require("../assets/candleColor.png"));
    } else {
      setImageSrc(require("../assets/candle.png"));
    }
    increaseCounter();
  };

  const increaseCounter = async () => {
    try {
      setCounter(post.counter + 1);
      await axios.post(`${API_URL}/api/posts/${post.id}/counter/up/`, {});
    } catch (error) {
      console.log("Error fetching posts:", error);
    }
  };
  if (!post) {
    return <div>Loading...</div>;
  }
  const deletePosts = async () => {
    const accessToken = localStorage.getItem("accessToken");
    try {
      const response = await axios.delete(`${API_URL}/api/posts/${post.id}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      window.location.href = "/";
    } catch (error) {
      console.log("Error fetching posts:", error);
    }
  };

  const modalStyle = {
    border: "3px solid black",
    backgroundColor: "white",
    alignItems: "center",
    maxHeight: "100%",
    height: "317px",
    fontSize: "small",
    maxHeight: "auto",
  };
  const mainAndBottomTextLength =
    (post.main_text?.length || 0) + (post.text_bottom?.length || 0);
  const shouldIncreaseSize = mainAndBottomTextLength <= 120;

  const isSmallScreen = window.innerWidth <= 1024;
  return (
    <Container
      data-aos={"fade-up"}
      className="post"
      id={modalIsOpen ? "pp-sm-open" : "pp-sm"}
      style={modalIsOpen ? { ...modalStyle, height: "auto" } : modalStyle}
    >
      {" "}
      <Row>
        <span
          className="post-type"
          style={{
            fontStyle: "italic",
            border: "none",
            borderTop: "none",
            textAlign: "right",
            backgroundColor: "white",
            color: "gray",
            fontSize: "smaller",
          }}
        >
          {isSerbianCyrillic(post.name)
            ? "последњи поздрав"
            : "posljednji pozdrav"}{" "}
        </span>
      </Row>
      <Row>
        <span
          style={{
            textAlign: "center",
            paddingBottom: "1%",
            fontSize: shouldIncreaseSize ? "14px" : "11px",
            lineHeight: shouldIncreaseSize ? "" : "1.1",
          }}
        >
          {isSmallScreen ? shortenString(post.text_top, 50) : post.text_top}
        </span>
      </Row>
      <Row style={{ justifyContent: "center" }}>
        <Col className="d-flex justify-content-center"></Col>
      </Row>
      <Row>
        <Col className=" justify-content-around"></Col>
        <Col className="d-flex justify-content-around">
          {post.images.length !== 0 && (
            <Carousel
              showThumbs={false}
              showArrows={false}
              infiniteLoop={true}
              autoPlay={true}
              interval={2500}
            >
              {post.images
                .reduce((p, c) => p + c)
                .split(",")
                .map((imagePath, index) => (
                  <div className="d-flex justify-content-center" key={index}>
                    <div
                      style={{
                        width: "110px",
                        height: shouldIncreaseSize ? "130px" : "115px",
                        border: "3px solid black",
                        background: `url(${API_URL}/${imagePath})`,
                        backgroundSize: "cover",
                        backgroundPosition: "50% 50%",
                      }}
                    ></div>
                  </div>
                ))}
            </Carousel>
          )}
        </Col>
        <Col className="d-flex justify-content-around"></Col>
      </Row>
      <span
        className="d-flex justify-content-around"
        style={{
          textAlign: "center",
          fontSize: shouldIncreaseSize ? "14px" : "11px",
          lineHeight: shouldIncreaseSize ? "" : "1.1",
          paddingTop: "2%",
          fontWeight: "bold",
        }}
      >
        {post.name.includes("\n")
          ? post.name
              .split("\n")
              .slice(0, 2)
              .map((line, index, array) => (
                <React.Fragment key={index}>
                  {line}
                  {index < array.length - 1 && <br />}
                </React.Fragment>
              ))
          : post.name}
      </span>
      {!modalIsOpen && (
        <Row style={{ marginTop: shouldIncreaseSize ? "5%" : "2%" }}>
          <span
            className="main-text"
            style={{
              textAlign: "left",
              fontSize: shouldIncreaseSize ? "14px" : "11px",
              lineHeight: shouldIncreaseSize ? "" : "1.1",
            }}
          >
            {post.main_text.includes("\n")
              ? post.main_text
                  .split("\n")
                  .slice(0, 2)
                  .map((line, index, array) => (
                    <React.Fragment key={index}>
                      {shortenString(line, 100)}
                      {index < array.length - 1 && <br />}
                    </React.Fragment>
                  ))
              : isSmallScreen
              ? shortenString(post.main_text, 80)
              : post.main_text}
            {post.main_text.split("\n").length > 2 && "..."}
          </span>
        </Row>
      )}
      {modalIsOpen && (
        <Row>
          <span style={{ textAlign: "left" }}>
            {" "}
            {post.main_text.split("\n").map((s, index) => (
              <p key={index} className="main-text-line">
                {s.trim()}
              </p>
            ))}
          </span>
        </Row>
      )}
      <Row
        style={{
          padding: "2%",
          paddingLeft: "0",
          paddingTop: "2%",
          paddingBottom: "2%",
        }}
      >
        {" "}
        <hr style={{ margin: "0", marginBottom: "1%" }}></hr>
        <span
          style={{
            textDecoration: "underline",
          }}
        >
          {isSerbianCyrillic(post.name) ? "Ожалошћени" : "Ožalošćeni"}
          {"..."}
        </span>
        {modalIsOpen && (
          <div
            style={{
              paddingTop: "1%",
            }}
          >
            {post.text_bottom.split("\n").map((s, index) => (
              <p className="bottom-text-line">{s.trim()}</p>
            ))}
          </div>
        )}
      </Row>{" "}
      {modalIsOpen && (userRole === "admin" || userRole === "superadmin") && (
        <Col>
          <button
            style={{ marginLeft: "3%" }}
            className="btn btn-danger"
            onClick={() => deletePosts()}
          >
            Obriši
          </button>
        </Col>
      )}
      {modalIsOpen && <ShareButtons id={post.id} />}
    </Container>
  );
}

export default SmallPoslednjiPozdrav;
