import React from "react";
import { API_URL } from "../config";

function Reklama({ ads, animate }) {
  let smallAd = null;
  ads.forEach((ad) => {
    if (ad.size === "medium") {
      smallAd = ad.image;
    }
  });

  return (
    <div
      style={{ marginTop: "20px" }}
      data-aos={animate === false ? "none" : "fade-up"}
      className="d-flex align-items-center justify-content-center"
    >
      {smallAd ? (
        <img
          style={{
            maxWidth: "100%",
            maxHeight: "315px",
          }}
          src={`${API_URL}/` + smallAd}
        />
      ) : (
        "Vaša reklama na: 067 XXX XXX"
      )}
    </div>
  );
}

export default Reklama;
