import React from "react";

import { Container, Col, Row } from "react-bootstrap";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the styles
function PreviewMdPP({ multiple }) {
  return (
    <Container fluid>
      <Row lg={12} md={12} sm={12}>
        <div
          style={{ marginTop: "20px", height: "auto" }}
          className="poslednji-pozdrav"
        >
          <p style={{ textAlign: "center" }}>Gornji tekst</p>
          <div className="title-container">
            <Col className="d-flex justify-content-around">
              {multiple && (
                <Carousel
                  showArrows={false}
                  infiniteLoop={true}
                  autoPlay={true}
                  interval={1000}
                >
                  <img
                    style={{
                      width: "150px",
                      height: "150px",
                      border: "3px solid black",
                    }}
                    src={require("../assets/user2.png")}
                    alt={`Image`}
                  />
                  <img
                    style={{
                      width: "150px",
                      height: "150px",
                      border: "3px solid black",
                    }}
                    src={require("../assets/user2.png")}
                    alt={`Image`}
                  />
                  <img
                    style={{
                      width: "150px",
                      height: "150px",
                      border: "3px solid black",
                    }}
                    src={require("../assets/user2.png")}
                    alt={`Image`}
                  />
                </Carousel>
              )}
              {!multiple && (
                <img
                  style={{
                    width: "150px",
                    height: "150px",
                    border: "3px solid black",
                  }}
                  src={require("../assets/user2.png")}
                  alt={`Image`}
                />
              )}
            </Col>
            <h4>Ime Prezime</h4>
            <h6 style={{ fontSize: "20px" }}>datum - datum</h6>
          </div>
          <p className="poslednji-pozdrav-big-text">
            Glavni tekst poslednjeg pozdrava
          </p>
          <p
            style={{
              paddingTop: "0",
              paddingBottom: "40px",
              paddingTop: "20px",
            }}
            className="ozalosceni-p-poslednji-pozdrav"
          >
            Od koga
          </p>
        </div>
      </Row>
    </Container>
  );
}

export default PreviewMdPP;
